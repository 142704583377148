import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Card from '@material-ui/core/Card'
import CollapsiblePanel from '../../components/CollapsiblePanel'
import { Icon, ButtonBase, Typography, Grid } from '@material-ui/core'
import useStyles from './styles'

const TabPanel = (props) => {
  const classes = useStyles()
  const { children, parentTranslationPath, icon, open, t, preview } = props

  const handleTabClick = () => {
    if (!props.open) {
      props.openCallback()
    }
  }

  return (
    <Fragment>
      <Card className={classNames(classes.tabCard)}>
        <ButtonBase 
          className={classNames(classes.fill)} 
          onClick={handleTabClick}
          disableRipple={true}>
          <div className={classNames(classes.tabCardTop)}>
            <div className={classNames(classes.title)}>
              { 
                icon && (
                  <Icon color={ open ? 'primary' : 'disabled' }>
                    { icon }
                  </Icon> 
                )
              }
              <Typography className={classNames(classes.title, open && 'active', 'small')}>
                { t(`${parentTranslationPath}.tabTitle`) }
              </Typography>
            </div>
          </div>
          <div className={classNames(classes.tabCardBody)}>
            {
              preview && ((preview.image && (
                <div className={classNames(classes.previewContainer)} style={{
                  padding: 0
                }}>
                  <img 
                    src={preview.image.link} 
                    alt={preview.image.alt} 
                    width={'100%'} height={'auto'} />
                </div>
              )) || (preview.icon && (
                <div className={classNames(classes.previewContainer)} style={{
                  borderColor: preview.icon.color
                }}>
                  <Icon className={classNames(classes.icon)}>
                    { preview.icon.description }
                  </Icon>
                </div>
              )))
            }
          </div>
        </ButtonBase>
      </Card>
      <div className={classNames(classes.panel)}>
        <CollapsiblePanel open={open}>
          <div className={classNames(classes.fill)}>
            <div className={classNames(classes.panelTitle)}>
              <Typography align='center' className={classNames(classes.title, 'large')}>
                { t(`${parentTranslationPath}.panelTitle`) }
              </Typography>
            </div>
            <Grid container spacing={2} className={classNames(classes.panelBody)}>
              { children }
            </Grid>
          </div>
        </CollapsiblePanel>
      </div>
    </Fragment>
  )
}

TabPanel.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.string,
  openCallback: PropTypes.func
}

export default TabPanel
