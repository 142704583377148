import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { KeyboardArrowRight } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  width: '100%',
  display: 'flex',
  label: {
    display: 'flex',
    'padding-top': '8px',
  },
  'label--alter': {
    'padding- top': '0px',
  },
  link: {
    'text-decoration': 'none',
  },
  icon: {
    'margin - top': '-4px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.8)',
    'font-weight': '500',
    'font-size': '12px',
  },
  first: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}))

const Breadcrumb = ({ data, theme }) => {
  const classes = useStyles(theme)

  return (
    <React.Fragment>
      {
        data !== undefined && data.length !== 0 ?
          data.map((item, idx) => {
            return (
              <div className={`${classes.label} ${data.length === 1 ? classes['label--alter'] : ''}`} key={`item-${idx}`}>
                <Typography className={`${classes.text} ${idx === 0 ? classes.first : ''}`}>
                  {'TLA'}
                </Typography>
                {data.length - 1 !== idx ?
                  <div className={`${classes.icon} ${classes.text}`}>
                    <KeyboardArrowRight />
                  </div> : <React.Fragment></React.Fragment>
                }
              </div>
            )
          }) : <React.Fragment></React.Fragment>
      }
    </React.Fragment>
  )
}

export default Breadcrumb
