import { gql } from '@apollo/client'

export default gql`
  query getAllCompanies {
    getAllCompanies {
      companyId
      description
      urlImage
    }
  }
`
