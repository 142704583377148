import { createTheme } from '@material-ui/core/styles'

export default createTheme({
  palette: {
    primary: {
      light: '#fedc41',
      main: '#FEBE10',
      dark: '#f1af01'
    },
    secondary: {
      light: '#4285f4',
      main: '#4285f4',
      dark: '#43425d'
    },
    type: 'light'
  },
  typography: {
    headline: {
      fontFamily: '"Natura Sans", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '22px',
      fontWeight: 'bold'
    },
    title: {
      fontFamily: '"Natura Sans", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '18px',
      fontWeight: 'bold'
    },
    subheading: {
      fontSize: '14px',
      fontWeight: '500'
    }
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        marginLeft: '3px'
      }
    }
  },
  utils: {
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
})
