import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  buttonEntry: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
    color: theme.palette.common.white,
    fontSize: theme.spacing(3),
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`
  },
  spanText: {
    marginLeft: 5,
    color: theme.palette.common.white
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey[100],
    padding: `0px ${theme.spacing.unit}px ${theme.spacing(2)}px ${theme.spacing.unit}px`,
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`
  },
  toolbarMargin: {
    ...theme.utils.center,
    justifyContent: 'space-between',
    ...theme.mixins.toolbar
  },
  root: {
    height: '100%',
    overflow: 'auto',
    flexGrow: 1
  },
  panelContainer: {
    height: '100%',
    float: 'left',
    margin: `0px ${theme.spacing(0.5)}px`,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut
    }),
  }
}))
