const style = (props) => {

  return `
  .button{
    background-color: ${ props.disabled ? '#e0e0e0' : '#4285f4'};
    color:  ${props.disabled ? '#4285f4' : '#fff'};
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 49px;
  }

  .text{
    padding-left: 10px;
    padding-right: 10px;
  }

  .big .text{
    padding-left: 40px;
    padding-right: 10px;
  }

  .button:hover{
    background-color: ${props.disabled ? '#4285f4' : '#e0e0e0'};
    color: ${props.disabled ? '#fff' : '#4285f4'};
  }

  .button-reverse{
    background-color: #f5f6fa !important;
    color:  #4285f4 !important;
  }

  .button-reverse:hover{
    color: #ffffff !important;
    background-color: #4285f4 !important;
  }

  .button-fill{
    background-color: #ffffff !important;
    color:  #4285f4 !important;
    border: 1px solid  #4285f4;
  }

  .button-fill:hover{
    color: #ffffff !important;
    background-color: #4285f4 !important;
  }

  .typeIcon{
    background-color: rgba(255, 0, 0, 0.0) !important;
    color:  #4285f4 !important;
  }

  .big{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: -18px !important;
  }

  .mini{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    color:  #4285f4 !important;
    border: #fff !important;
    background-color: #fff !important;
  }

  .mini:hover{
    background-color: #fff !important;
    color: #c0c0c0 !important;
  }
`}

export default style