import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'

import IconButton from '@material-ui/core/IconButton'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
// import logoNatura from '../../../assets/images/natura.png'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    backgroundColor: '#43425d',
    minHeight: '100vh'
  },
  drawerOpen: {
    backgroundColor: '#43425d',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#43425d',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
  },
  drawerPaper: {
    position: 'fixed',
    overflow: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#43425d',
    color: '#fff'
  },
  drawerPaperClose: {
    width: 70,
    flexGrow: 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#43425d',
    color: '#fff'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    color: '#fff',
    display: 'flex',
    lineHeight: '12px',
    marginLeft: '25px',
  },
  logoImg: {
    maxHeight: '32px !important',
    marginLeft: '40px',
    maxWidth: '100% !important',
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  subTitleOpen: {
    width: '100%',
    padding: '20px 0px 30px 0px',
    textAlign: 'center',
  },
  subTitleClose: {
    width: '0px',
    padding: '0px',
    visibility: 'hidden'
  },
  subTitleText: {
    color: '#ffffff',
    opacity: '0.6',
    fontSize: '12px'
  },
}))

export default function MenuComponent({ handleDrawerToggle, menuOpen, children, setMenuOpenList }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  function closeAllMenu() {
    if (menuOpen) {
      setMenuOpenList((prevState) => {
        return prevState.map((item) => false)
      })
    }

    handleDrawerToggle()
  }

  return (
    <React.Fragment>
      <Drawer
        open={menuOpen}
        variant="permanent"
        onClose={closeAllMenu}
        classes={{
          paper: classNames(classes.drawerPaper, !menuOpen && classes.drawerPaperClose),
        }}
      >
        <div alt={'icon'} className={classes.toolbar}>

          {/* <Typography variant='h1' className={classes.logo}>
            <img alt='' src={logoNatura} className={classes.logoImg} />
          </Typography> */}

          <IconButton className={classes.logo} onClick={closeAllMenu} >
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>

        </div>

        <Divider style={{ backgroundColor: '#fff', opacity: '0.3' }} />

        <div className={classNames(classes.subTitleOpen, !menuOpen && classes.subTitleClose)}>
          <Typography className={classes.subTitleText}>
            {t('titles.appBar.main')}
          </Typography>
        </div>

        {children}
      </Drawer>
    </React.Fragment>
  )
}