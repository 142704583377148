import gql from 'graphql-tag'

export default gql`
mutation (
  $moduleUid: String!
){
  deleteModule(
    moduleUid: $moduleUid
  )
}
`
