import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  main: {
    ...theme.utils.center,
    boxShadow: theme.shadows[0],
    height: '100%',
    borderRadius: theme.spacing.unit,
    padding: theme.spacing(2),
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.standard
    }),
  },
  content: {
    height: '100%',
    width: '100%',
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest
    }),
    '&.hidden': {
      display: 'none',
    },
    '&.visible': {
      display: 'block',
    },
  }
}))