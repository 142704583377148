import { gql } from '@apollo/client'

export default gql`
  query GetXAppToken(
    $roles: [String]!
    $moduleUid: String!
    $countryCode: String!
    $countryId: Int!
    $companyId: Int!
    $language: String
    ) {
    getXAppToken(
      roles: $roles
      moduleUid: $moduleUid
      countryCode: $countryCode
      countryId: $countryId
      companyId: $companyId
      language: $language
    ) {
      token
    }
  }
`
