import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
 
export default function CellResume(props) {
  const { maxItems, values } = props

  const enableSeeMore = values.length > maxItems

  const allValues = values.join(', ')

  const resumedValues = enableSeeMore ? [...values.slice(0, maxItems)].join(', ') : allValues
    
  return (
    <span>
      {resumedValues} 
      {enableSeeMore && <Tooltip title={allValues}>
        <IconButton>
          <MoreHoriz />
        </IconButton>
      </Tooltip>}
    </span>
  )
}
