import i18n from 'i18next'

export const savedLanguage = () => {
  const { localStorage: storage } = window
  return storage.getItem('savedLanguage')
}

export const availableLanguage = ['pt', 'en', 'es']


export const languageResolver = (language, countryCode) => {
  const languageMap = {
    [language]: { [countryCode]: 'es-PE' },
    pt: { [countryCode]: 'pt-BR', BR: 'pt-BR', },
    en: { [countryCode]: 'en-US', US: 'en-US', },
    es: { [countryCode]: 'es-PE', CO: 'es-CO', PE: 'es-PE' , MX: 'es-MX' },
  }
  const langCountryMap = languageMap[language]
  const final = langCountryMap[countryCode]
  return final
}

export const saveLanguage = (lang) => {
  i18n.changeLanguage(lang)
  localStorage.setItem('savedLanguage', lang)
}

export const fallbackLng = (defaultLanguange = navigator.language.split('-').shift()) => savedLanguage() || (languageResolver(defaultLanguange, navigator.language.split('-').pop()))
