import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#f6f6f6',
  },
  button: {
    color: '#f6f6f6',
  },
  label: {
    'font-size': '22px',
    'font-family': '\'Natura Sans\', Roboto, Helvetica, Arial, sans-serif\'',
    'font-weight': '500',
  },
  padding: '20px 0 41px 0',
}))

function PageBase(props) {
  const classes = useStyles()

  return (
    <div className={classes.background}>
      <Typography className={classes.label}>
        {props.title}
      </Typography>
      {props.children}
    </div>
  )
}

export default PageBase
