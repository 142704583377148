import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  appBar: {
    color: '#707070',
    display: 'flex',
    background: theme.palette.background,
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    maxHeight: '64px',
    ...theme.mixins.appBar
  },
  toolbar: {
    ...theme.utils.center,
    justifyContent: 'space-between',
    ...theme.mixins.toolbar,
    backgroundColor: 'transparent',
    padding: `0px ${theme.spacing.unit * 1.5}px`,
    color: 'black',
  },
  block: {
    ...theme.utils.center,
    justifyContent: 'space-between',
    width: theme.spacing(60)
  },
  menuButton: {
    margin: theme.spacing.unit,
    width: theme.spacing(30),
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuOption: {
    textAlign: 'center',
  },
  langIcon: {
    textTransform: 'uppercase',
    fontSize: '16px',
    marginRight: theme.spacing.unit,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
  },
  divider: {
    margin: '0 10px 0 5px'
  },
  moduleTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '1.16667em',
    fontFamily: '"Natura Sans", Roboto, Helvetica, Arial, sans-serif',
  }
}))
