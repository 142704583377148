import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import classNames from 'classnames'

import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

import Breadcrumb from '../Breadcrumb'
import BarFix from './BarFix'

import Profile from '../../containers/Profile'

const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    borderTop: '6px solid #00EB91',
    paddingBottom: '58px ',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    color: '#bcbccb',
    height: '58px'
  },
  appBarShift: {
    paddingLeft: 'calc(25%)',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  margin: {
    margin: theme.spacing(3),
  },
  menuButton: {
    margin: 'auto 24% auto -12px',
  },
  menuRigthButton: {
    color: '#bcbccb',
    marginLeft: 'auto',
    marginRight: -12,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

export default function AppBarComponent({ handleDrawerToggle, menuOpen, pages, currentUser, theme }) {
  const classes = useStyles(theme)
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar, {
        [classes.appBarShift]: menuOpen,
      })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classNames(classes.menuButton, menuOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <div>
            <Breadcrumb data={pages} />
          </div>

          <BarFix>
            {t('titles.appBar.main')}
          </BarFix>

          {currentUser ?
            <div className={classes.menuRigthButton}>
              <Profile currentUser={currentUser} />
            </div> : <React.Fragment></React.Fragment>}

        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}