import { gql } from '@apollo/client'

export default gql`
  query GetCountryAccess(
    $roles: [String]!
    $companyId: Int!
    ) {
    getCountryAccess(
      roles: $roles,
      companyId: $companyId
      ) {
      countryCode
      countryId
      description
      urlImage
      language
    }
  }
`
