import { gql } from '@apollo/client'

export default gql`
  query getAllRoutes {
    getAllRoutes {
      routeUid
      description
      createdBy
      createdAt
      updatedBy
      updatedAt
      module {
        moduleUid
        name
      }
    }
  }
`