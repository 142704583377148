import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import Grid from '@material-ui/core/Grid'
import Add from '@material-ui/icons/Add'
import Cancel from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import DynamicInput from '../../components/DynamicInput'
import ButtonDefault from '../../components/ButtonDefault'
import ListRole from '../ListRole'
import MultiOption from '../../components/MultiOption'

export default function PermissionSelector(props) {
  const { t } = useTranslation()

  const { data, modules } = props

  const formRef = useRef(null)
  const [routes, setRoutes] = useState([])
  const [role, setRole] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [editingIndex, setEditingIndex] = useState(null)

  const groupedOptions = modules.map((module) => ({
    label: module.name,
    options: module.routes.map((route) => ({
      label: route.description,
      value: route.routeUid,
      id: module.name,
    })),
  }))
  
  const columns = [
    { id: 'role', label: t('labels.role'), fixedOnScroll: true, width: 150 },
    ...modules.map((module) => ({ id: module.name, label: module.name, width: 100 })),
  ]

  const onRoleChange = (value) => setRole(value.toUpperCase())

  const onSelectAllRoutes = () => {
    const routes = []

    modules.forEach((module) => {
      module.routes.forEach((route) => {
        routes.push({
          id: module.name,
          label: route.description,
          value: route.routeUid,
        })
      })
    })

    setRoutes(routes)
  }

  const onRemoveModuleAccess = (indexToRemove) => {
    const list = data.filter((_, index) => index !== indexToRemove)

    props.onChange(list)
  }

  const onAddModuleAccesss = () => {
    if (editingIndex !== null) {
      const nameExists = data
        .filter((access, index) => access.role === role && index != editingIndex)

      if (nameExists.length > 0) {
        toastr.error(t('pages.wizard.errors.duplicatedRole'))
  
        return
      }

      data[editingIndex] = { ...data[editingIndex], role, routes }
  
      props.onChange(data)

      clearForm()

      return
    }
    
    const roleNameExists = data.map((access) => access.role).includes(role)

    if (roleNameExists) {
      toastr.error(t('pages.wizard.errors.duplicatedRole'))

      return
    }

    props.onChange([...data, { role, routes }])

    clearForm()
  }

  const clearForm = () => {
    setRoutes([])
    setRole('')
    setEditingIndex(null)
  }

  const onEditModuleAccess = (indexToEdit) => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ block: 'start' })
    }

    const current = data.find((_, index) => index === indexToEdit)

    setEditingIndex(indexToEdit)
    setRole(current.role)
    setRoutes(current.routes)
  }

  const isRouteAccessValid = () => {
    return (role !== '' && routes.length > 0)
  }

  const formatPermissionsList = () => {
    return data.map((routeAccess) => {
      let currentLine = {
        role: routeAccess.role,
      }

      modules.forEach((module) => {
        currentLine[module.name] = routeAccess.routes
          .filter((route) => route.id === module.name)
          .map((route) => route.label)
      })

      return currentLine
    })
  }

  return (
    <Grid ref={formRef} container spacing={3} justifyContent="space-between">
      <Grid item xs={5}>
        <Typography>
          {t('labels.role')}
        </Typography>
        <DynamicInput
          input={'role'}
          maxLength={25}
          ref={formRef}
          onInput={(value) => onRoleChange(value)}
          id={{
            placeholder: t('placeholders.role'),
          }}
          value={role}
        />
      </Grid>
      <Grid item xs={5}>
        <ButtonDefault
          text={t('labels.selectAll')}
          onClick={onSelectAllRoutes}
          loading={false}
          IconProps={Add}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {t('labels.routes')}
        </Typography>
        <MultiOption
          placeholder={t('placeholders.routes')}
          isMulti={true}
          allowSelectAll={true}
          closeMenuOnSelect={false}
          value={routes}
          onChange={setRoutes}
          disabled={false}
          groupedOptions={groupedOptions}
          properties={{ label: 'name', value: 'name', id: 'routeUid' }}
        />
      </Grid>
      <Grid item xs={2}>
        <ButtonDefault
          text={t('labels.add')}
          onClick={onAddModuleAccesss}
          loading={false}
          disabled={!isRouteAccessValid()}
          IconProps={Add}
        />
      </Grid>
      <Grid item xs={2}>
        <ButtonDefault
          text={t('labels.cancel')}
          onClick={clearForm}
          loading={false}
          IconProps={Cancel}
        />
      </Grid>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} >
          <ListRole
            columns={columns}
            data={formatPermissionsList()}
            onRemove={onRemoveModuleAccess}
            onEdit={onEditModuleAccess}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}