import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import propTypes from 'prop-types'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'

import { Context } from '../../context'

const MenuBox = styled.div`
  .button{
    font-size: 12px;
    border-left: 1px solid #dfdfdf;
    font-weight: 400;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .button:hover{
    background-color: #ffffff;
    opacity: 0.5;
  }

  .menu{
    position: fixed;
    margin-top: 2px;
  }

  .menu-item{
    position: relative;
    background-color: #ffffff;
  }

  .active{
    color: #fff;
  }

  .active-icon{
   color: #bcbccb;
  }

  .menu-closed{
    z-index: -1;
    position: absolute;
    background-color: red;
    top: -200px;
    opacity: 1 !important;
  }
`

function MenuUser(props) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(false)

  const [context] = useContext(Context)

  const { currentUser } = context

  function handleClick() {
    setAnchorEl(!anchorEl)
  }

  function handleClose() {
    setAnchorEl(false)

    currentUser.logout()
  }

  const { userName } = props

  return (
    <MenuBox>

      <Button
        onClick={handleClick}
        className='button'
      >
        {userName}   {anchorEl ? <ExpandLess className='active-icon' /> : <ExpandMore className='active-icon' />}
      </Button>

      <div className={`menu ${anchorEl ? ' ' : 'menu-closed'}`}>
        <Grow in={anchorEl} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
          <Paper>
            <MenuList>
              <MenuItem className='menu-item' onClick={handleClose}>
                {t('topBar.logout')}
              </MenuItem>
            </MenuList>
          </Paper>
        </Grow>
      </div>
    </MenuBox>

  )
}

MenuUser.propTypes = {
  userName: propTypes.string.isRequired
}


export default MenuUser
