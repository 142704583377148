import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Card from '@material-ui/core/Card'
import useStyles from './styles'

let animationInMiliseconds = 300

const CollapsiblePanel = (props) => {
  const classes = useStyles()
  const [contentVisible, setContentVisible] = useState(props.open)

  const { open, children } = props

  const showContent = () => {
    if (props.open !== contentVisible) {
      setTimeout(() => {
        setContentVisible({
          contentVisible: props.open
        })
      }, props.open ? animationInMiliseconds : 0)
    }
  }

  showContent(open)

  return (
    <Card className={classNames(classes.main)} style={{
      width: open ? '100%' : '0%',
      display: open ? 'flex' : 'none'
    }}>
      <div 
        className={classNames(classes.content, open ? 'visible' : 'hidden')}
        style={{ opacity: contentVisible ? '1' : '0' }}>
        { children }
      </div>
    </Card>
  )
}

CollapsiblePanel.propTypes = {
  open: PropTypes.bool,
}

export default CollapsiblePanel