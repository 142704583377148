import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { toastr } from 'react-redux-toastr'
import CartWithTitle from '../../components/CardWithTitle'
import CompanyStep from '../../containers/CompanyStep'
import CountryStep from '../../containers/CountryStep'
import ModuleStep from '../../containers/ModuleStep'
import Wizard from '../../containers/Wizard'
import MainContainer from '../../containers/Admin'
import PageBase from '../PageBase'
import updateCompanyCountryMutation from '../../../graphql/save-company-country-access'

export default function WizardPage(props) {
  const { t } = useTranslation()

  const [activeStep, setActiveStep] = useState(0)

  const [saveCompanyCountryAccess, { loading }]
  = useMutation(updateCompanyCountryMutation)

  const formatPayload = (data, fieldName) => {
    if (!data.isNew) return { code: data[fieldName].value }

    delete data[fieldName]
    delete data.isCompleted
    delete data.isNew

    return data
  }

  const onSubmit = (data) => {
    const { company, country, module: { routeAccess, isEnabled } } = data

    const payload = {
      company: formatPayload(company, 'companyId'),
      country: formatPayload(country, 'countryId'),
      isEnabled: isEnabled === null ? true : isEnabled,
      moduleAccess: routeAccess.map((access) => ({
        role: access.role, routeUids: access.routes.map((route) => route.value),
      }))
    }

    saveCompanyCountryAccess({
      variables: payload
    })
      .then(() => {
        toastr.success(t('toastr.success'))
      })
      .catch(() => {
        setActiveStep(2)

        toastr.error(t('toastr.error'))
      })
  }

  return (
    <MainContainer {...props}>
      <PageBase {...props}>
        <CartWithTitle title={t('titles.managePage')}>
          <Wizard loading={loading} steps={[{
            name: 'company',
            label: 'labels.company',
            component: CompanyStep
          }, {
            name: 'country',
            label: 'labels.country',
            component: CountryStep
          }, {
            name: 'module',
            label: 'labels.permissionsAndModules',
            component: ModuleStep
          }]}
          onSubmit={onSubmit}
          activeStep={activeStep}
          />
        </CartWithTitle>
      </PageBase>
    </MainContainer>
  )
}