import { gql } from '@apollo/client'

export default gql`
  query GetAccessModule(
    $roles: [String]!
    $companyId: Int!
    $countryId: Int!
    ) {
    getAccessModule(
      roles: $roles,
      companyId: $companyId,
      countryId: $countryId
      ) {
      moduleUid,
      name,
      url,
      description,
      iconName,
      iconColor,
      order
    }
  }
`
