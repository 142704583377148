import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'

import { useQuery } from '@apollo/client'

import ComboBox from '../../components/ComboBox'
import DynamicInput from '../../components/DynamicInput'
import ButtonDefault from '../../components/ButtonDefault'
import CartWithTitle from '../../components/CardWithTitle'

import AllCountriesQuery from '../../../graphql/get-all-countries'

const initialState = {
  countryId: null,
  code: null,
  countryCode: '',
  countryCode3: '',
  countryNumber: 0,
  currencyCode: '',
  currencyFormat: '',
  currencyName: '',
  currencyNumber: 0,
  dateFormat: '',
  decimalPlaces: 0,
  description: '',
  englishDescription: '',
  language: '',
  timeZoneAbreviation: '',
  urlImage: '',
  utc: '',
  isNew: null,
}

export default function CountryStep(props) {
  const { t } = useTranslation()

  const { data } = props

  const [error, setError] = useState(false)
  const [allCountries, setAllCountries] = useState([])

  const { loading: loadingAllCountries } = useQuery(AllCountriesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAllCountries }) => {
      setAllCountries(getAllCountries)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const onCreateNew = () => {
    const emptyState = {
      ...initialState, isNew: true,
    }

    props.dataChanged(emptyState)
  }

  const onCountryChange = (value) => {
    const data = {
      countryId: value, description: '', urlImage: '', isNew: false,
    }

    const isCompleted = isStepComplete(data)

    props.dataChanged({ ...data, isCompleted })
  }

  const setData = (fieldName, input) => {
    const isCompleted = isStepComplete({
      ...data, [fieldName]: input,
    })

    props.dataChanged({ [fieldName]: input, isCompleted })
  }


  const isStepComplete = (obj) => {
    return ((obj.isNew === false && obj.countryId != null)
      || (obj.isNew === true
        && obj.code !== ''
        && obj.countryCode !== ''
        && obj.countryCode3 !== ''
        && obj.countryNumber !== ''
        && obj.currencyCode !== ''
        && obj.currencyFormat !== ''
        && obj.currencyName !== ''
        && obj.currencyNumber !== ''
        && obj.dateFormat !== ''
        && obj.decimalPlaces !== ''
        && obj.description !== ''
        && obj.englishDescription !== ''
        && obj.language !== ''
        && obj.timeZoneAbreviation !== ''
        && obj.urlImage !== ''
        && obj.utc !== ''
      )
    )
  }

  return (
    <React.Fragment>
      <CartWithTitle title={t('labels.countryCardTitle')}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={4}>
            <ComboBox
              placeholder={t('labels.selectExistingCountry')}
              value={data.countryId}
              onChange={(value) => onCountryChange(value)}
              data={allCountries}
              disabled={false}
              loading={loadingAllCountries}
              properties={{ label: 'description', value: 'countryId', id: 'countryId' }}
            />
          </Grid>          
          <Grid item xs={2}>
            <Typography>{t('labels.or')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <ButtonDefault
              text={t('labels.createNew')}
              onClick={onCreateNew}
              // loading={loadingSaveTolerance}
              IconProps={Add}
            />
          </Grid>
        </Grid>
      </CartWithTitle>
      {data.isNew && <CartWithTitle title={t('labels.countryDetails')}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={5}>
            <Typography>
              {t('labels.code')}
            </Typography>
            <DynamicInput
              number={true}
              input={'code'}
              onInput={(value) => setData('code', value)}
              id={{
                placeholder: t('placeholders.code'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.code}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.countryCode')}
            </Typography>
            <DynamicInput
              input={'countryCode'}
              onInput={(value) => setData('countryCode', value)}
              id={{
                placeholder: t('placeholders.countryCode'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.countryCode}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.countryCode3')}
            </Typography>
            <DynamicInput
              input={'countryCode3'}
              onInput={(value) => setData('countryCode3', value)}
              id={{
                placeholder: t('placeholders.countryCode3'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.countryCode3}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.countryNumber')}
            </Typography>
            <DynamicInput
              number={true}
              input={'countryNumber'}
              onInput={(value) => setData('countryNumber', value)}
              id={{
                placeholder: t('placeholders.countryNumber'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.countryNumber}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.currencyCode')}
            </Typography>
            <DynamicInput
              input={'currencyCode'}
              onInput={(value) => setData('currencyCode', value)}
              id={{
                placeholder: t('placeholders.currencyCode'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.currencyCode}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.currencyFormat')}
            </Typography>
            <DynamicInput
              input={'currencyFormat'}
              onInput={(value) => setData('currencyFormat', value)}
              id={{
                placeholder: t('placeholders.currencyFormat'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.currencyFormat}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.currencyName')}
            </Typography>
            <DynamicInput
              input={'currencyName'}
              onInput={(value) => setData('currencyName', value)}
              id={{
                placeholder: t('placeholders.currencyName'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.currencyName}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.currencyNumber')}
            </Typography>
            <DynamicInput
              number={true}
              input={'currencyNumber'}
              onInput={(value) => setData('currencyNumber', value)}
              id={{
                placeholder: t('placeholders.currencyNumber'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.currencyNumber}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.dateFormat')}
            </Typography>
            <DynamicInput
              input={'dateFormat'}
              onInput={(value) => setData('dateFormat', value)}
              id={{
                placeholder: t('placeholders.dateFormat'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.dateFormat}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.decimalPlaces')}
            </Typography>
            <DynamicInput
              number={true}
              input={'decimalPlaces'}
              onInput={(value) => setData('decimalPlaces', value)}
              id={{
                placeholder: t('placeholders.decimalPlaces'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.decimalPlaces}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.description')}
            </Typography>
            <DynamicInput
              input={'description'}
              onInput={(value) => setData('description', value)}
              id={{
                placeholder: t('placeholders.description'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.description}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.englishDescription')}
            </Typography>
            <DynamicInput
              input={'englishDescription'}
              onInput={(value) => setData('englishDescription', value)}
              id={{
                placeholder: t('placeholders.englishDescription'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.englishDescription}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.language')}
            </Typography>
            <DynamicInput
              input={'language'}
              onInput={(value) => setData('language', value)}
              id={{
                placeholder: t('placeholders.language'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.language}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.timeZoneAbreviation')}
            </Typography>
            <DynamicInput
              input={'timeZoneAbreviation'}
              onInput={(value) => setData('timeZoneAbreviation', value)}
              id={{
                placeholder: t('placeholders.timeZoneAbreviation'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.timeZoneAbreviation}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.urlImage')}
            </Typography>
            <DynamicInput
              input={'urlImage'}
              maxLength={100}
              onInput={(value) => setData('urlImage', value)}
              id={{
                placeholder: t('placeholders.urlImage'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.urlImage}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography>
              {t('labels.utc')}
            </Typography>
            <DynamicInput
              input={'utc'}
              onInput={(value) => setData('utc', value)}
              id={{
                placeholder: t('placeholders.utc'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={data.utc}
            />
          </Grid>
        </Grid>
      </CartWithTitle>}
    </React.Fragment>
  )
}