import { gql } from '@apollo/client'

export default gql`
  query getUserProfile(
    $roles: [String]!
    ) {
    getUserProfile(
      roles: $roles
    ) {
      isAdmin
    }
  }
`
