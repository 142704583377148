import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { withAuthCognito } from 'globalsales-hoc-cognito'

import { BrowserRouter } from 'react-router-dom'

import { Provider as ContextProvider } from './app/context'

// import registerServiceWorker from './registerServiceWorker'
import store from './store'

import './i18n'
import App from './app'

const WrapperComponent = (props) => (
  <Provider store={store}>
    <BrowserRouter>
      <ContextProvider>
        <App { ...props } />
      </ContextProvider>
    </BrowserRouter>
  </Provider>
)

const AppWithAuth = withAuthCognito(WrapperComponent, {
  CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
  CALLBACK_URL: process.env.REACT_APP_COGNITO_CALLBACK_URL,
})

ReactDOM.render(
  <AppWithAuth />,
  document.getElementById('root')
)

