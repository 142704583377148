import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    display: 'block',
    height: '100%'
  },
  typography: {
    margin: `${theme.spacing.unit}px 0px`,
    fontFamily: '"Natura Sans", Roboto, Helvetica, Arial, sans-serif',
    fontSize: '15px',
    width: theme.spacing(31),
    height:'45%',
  },
  outerIconContainer: {
    height: theme.spacing(12),
    width: theme.spacing(12),
    padding: theme.spacing.unit,
    borderRadius: theme.spacing(2),
    transition: theme.transitions.create('background-color')
  },
  iconContainer: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    padding: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: theme.spacing(0.5),
    borderRadius: theme.spacing(2),
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shorter
    })
  },
  icon: {
    color: theme.palette.grey[700],
    fontSize: theme.spacing(5),

    '&.active': {
      color: theme.palette.grey[100],
    },
  },
  graphicContainer: {
    ...theme.utils.center,
    height: '55%'
  },
  imageContainer: {
    ...theme.utils.center,
    width: theme.spacing(11),
    height: theme.spacing(11),
  }
}))