import gql from 'graphql-tag'

export default gql`
mutation (
  $module: InputModule!
){
  mutateModule(
    module: $module
  )
}
`
