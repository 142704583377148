import gql from 'graphql-tag'

export default gql`
mutation (
  $routeUid: String!
){
  deleteRoute(
    routeUid: $routeUid
  )
}
`
