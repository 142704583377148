import React from 'react'
import { Route } from 'react-router-dom'

import Login from '../app/pages/Login'
import Modules from '../app/pages/Modules'
import Admin from '../app/pages/Admin'
import Wizard from '../app/pages/Wizard'
import ModuleList from '../app/pages/Module'
import RouteList from '../app/pages/Route'

const routesList = [
  {
    path: '/login',
    exact: true,
    component: Login
  },
  {
    path: '/',
    exact: true,
    component: Modules
  },
  {
    path: '/admin',
    exact: true,
    component: Admin,
    isRestricted: true,
  },
  {
    path: '/admin/wizard',
    exact: true,
    component: Wizard,
    isRestricted: true,
  },
  {
    path: '/admin/modules',
    component: ModuleList,
    isRestricted: true,
  },
  {
    path: '/admin/routes',
    component: RouteList,
    isRestricted: true,
  }
]

export const RouteWithSubRoutes = (route) => (
  <Route
    exact={route.exact}
    path={route.path}
    render={(props) => (
      <route.component
        {...props}
        {...route}
        routes={route.routes}
        clearForm={route.clearForm}
        t={route.t}
      />
    )}
  />
)

export default routesList
