/* eslint-disable */
import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { 
  Button, Input, 
  FormControl, InputAdornment, InputLabel, 
  IconButton, Icon, Typography
} from '@material-ui/core'
import useStyles from './styles'
import { toastr } from 'react-redux-toastr'

export default function LoginForm(props) {
  const classes = useStyles(props.theme)
  const { t } = useTranslation()

  const [state, setState] = useState({
    user: '',
    password: '',
    passwordVisible: false,
    authorized: false
  })

  const handleChange = (key, event) => {
    setState({
      ...state,
      [key]: event.target.value
    })
  }

  const showPassword = () => {
    setState({
      ...state,
      passwordVisible: !state.passwordVisible
    })
  }

  const handleLogin = () => {
    if(state.password.length > 0 && state.user.length > 0) {
      props.setAuthorization(true)
      return
    }

    toastr.error(t('toastr.error'), t('pages.login.error.missingInput'))
  }

  return (
    <React.Fragment>
      <div className={classNames(classes.baseContainer, classes.titleContainer)}>
        <Typography variant="headline">{t('pages.login.title')}</Typography>
      </div>
      <div className={classNames(classes.baseContainer, classes.inputContainer)}>
        <div>
          <FormControl className={classNames(classes.margin, classes.textField)}>
            <InputLabel htmlFor="user">{t('pages.login.labels.user')}</InputLabel>
            <Input
              id='user'
              type={'text'}
              value={state.user}
              onChange={(value) => handleChange('user', value)}/>
          </FormControl>
          <br />
          <FormControl className={classNames(classes.margin, classes.textField)}>
            <InputLabel htmlFor="password">{t('pages.login.labels.password')}</InputLabel>
            <Input
              id="password"
              type={state.passwordVisible ? 'text' : 'password'}
              value={state.password}
              onChange={(value) => handleChange('password', value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('pages.login.labels.showPassword')}
                    onClick={showPassword}
                  >
                    {state.passwordVisible 
                      ? <Icon>{'visibility'}</Icon> 
                      : <Icon>{'visibility_off'}</Icon>}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
      <div className={classNames(classes.baseContainer, classes.buttonContainer)}>
        <Button 
          className={classNames(classes.button)}
          onClick={handleLogin}>{t('pages.login.button')}</Button>
      </div>
    </React.Fragment>
  )
}
