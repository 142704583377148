import gql from 'graphql-tag'

export default gql`
mutation (
  $company: InputCompany!
  $country: InputCountry!
  $isEnabled: Boolean!
  $moduleAccess: [InputModuleAccess]
){
    mutateCompanyCountry(
      company: $company
      country: $country
      isEnabled: $isEnabled
      moduleAccess: $moduleAccess
  )
}
`
