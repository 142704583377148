import gql from 'graphql-tag'

export default gql`
mutation (
  $route: InputRoute!
){
  mutateRoute(
    route: $route
  )
}
`
