import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import EditCircle from '@material-ui/icons/EditOutlined'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CellResume from '../CellResume'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    border: 'none'
  },
  table: {
    height: '150px',
    textAlign: 'center',
    width: '100%',
    zIndex: 50,
  },
  tableBody: {
    height: '100px',
    overflowY: 'scroll',
    width: '100%'
  },
  tableRow: {
    background: theme.palette.background.default,
    border: '4px solid',
    borderColor: theme.palette.background.paper
  },
  stickyHead: {
    position: 'sticky',
    textAlign: 'left',
    left: 0,
    width: 48,
    padding: 0,
    zIndex: 100,
    background: theme.palette.background.default,
  },
  stickyHead1: {
    position: 'sticky',
    textAlign: 'left',
    left: 48,
    width: 48,
    padding: 0,
    zIndex: 100,
    background: theme.palette.background.default,
  },
  stickyHead2: {
    position: 'sticky',
    textAlign: 'left',
    left: 96,
    zIndex: 100,
    background: theme.palette.background.default,
    borderRight: '1px solid lightgray'
  },
  stickyColumn: {
    position: 'sticky',
    textAlign: 'left',
    left: 0,
    zIndex: 50,
    width: 48,
    padding: 0,
    background: theme.palette.background.default,
  },
  stickyColumn1: {
    position: 'sticky',
    textAlign: 'left',
    left: 48,
    width: 48,
    zIndex: 50,
    padding: 0,
    background: theme.palette.background.default,
  },
  stickyColumn2: {
    position: 'sticky',
    textAlign: 'left',
    left: 96,
    zIndex: 50,
    background: theme.palette.background.default,
    borderRight: '1px solid lightgray'
  },
  iconButton: {
    // borderRadius: theme.spacing(1),
  }
}))

function ListRole(props) {
  const classes = useStyles()
  const { data } = props

  if (!data || data.length === 0) return <React.Fragment></React.Fragment>

  return (
    <Paper className={classes.root} elevation={0}>
      <div style={{ overflow: 'auto', maxHeight: '500px' }} >
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.stickyHead}>
              </TableCell>
              <TableCell className={classes.stickyHead1}>
              </TableCell>
              {props.columns.map((column, index) => (
                <TableCell key={index} className={column.fixedOnScroll && classes.stickyHead2}>
                  <Typography variant="inherit">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {data.map((item, index) => (
              <TableRow className={classes.tableRow} key={index}>
                <TableCell className={classes.stickyColumn}>
                  <IconButton className={classes.iconButton} onClick={() => props.onRemove(index)}>
                    <RemoveCircle color={'error'}/>
                  </IconButton>
                </TableCell>
                <TableCell className={classes.stickyColumn1}>
                  <IconButton className={classes.iconButton} onClick={() => props.onEdit(index)}>
                    <EditCircle />
                  </IconButton>
                </TableCell>
                {props.columns.map((column, indexColumn) => (
                  <TableCell key={indexColumn} className={column.fixedOnScroll && classes.stickyColumn2}>
                    {Array.isArray(item[column.id]) ? 
                      <CellResume maxItems={2} values={item[column.id]} /> : item[column.id]
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      </div>
    </Paper >
  )
}

ListRole.propTypes = {
  data: PropTypes.array,
  onRemove: PropTypes.func,
  icon: PropTypes.element,
  classes: PropTypes.object,
  t: PropTypes.func
}

export default ListRole
