import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Icon, Menu, MenuItem } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import { availableLanguage, languageResolver } from '../../../enum/languages-whitelist'
import { useTranslation } from 'react-i18next'

import { Context } from '../../context'

function TopBar(props) {
  const [userState] = useContext(Context)

  const classes = useStyles()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [state, setState] = useState({
    open: false,
    anchorEl: null,
    anchorEl2: null,
    anchorEl3: null,
    colorModule: null,
    selectedIndex: null
  })

  const { open, selectedLanguage } = props
  const { anchorEl2, anchorEl3, selectedIndex } = state
  const { currentUser } = userState
  const { user } = currentUser

  const handleLogout = () => {
    currentUser.logout()
  }

  const handleClickLogout = (event) => {
    setState({ ...state, anchorEl3: event.currentTarget })
  }

  const handleCloseLogout = () => {
    setState({ ...state, anchorEl3: null })
  }

  const handleClickLanguage = (event) => {
    setState({ ...state, anchorEl2: event.currentTarget })
  }

  const handleCloseLanguage = () => {
    setState({ ...state, anchorEl2: null })
  }

  const onChangeLng = (event) => {
    const resolvedLang = languageResolver(event.currentTarget.id, props.selectedCountryCode)
    props.setLanguageAutoSelectDefault(false)
    props.setLanguage(resolvedLang)
    setState({ selectedIndex: resolvedLang.split('-').shift(), anchorEl2: null })
  }

  return (
    <AppBar
      position="absolute"
      className={classNames(classes.appBar)}
      elevation={0}
      color="default"
    >
      <Toolbar disableGutters={!open} className={classNames(classes.toolbar)}>
        <div className={classNames(classes.moduleTitle)}>
          <Typography noWrap align="center" variant={'string'}>
            {t('topBar.moduleTitle')}
          </Typography>
        </div>
        <div className={classNames(classes.block)}>
          <IconButton
            aria-haspopup="true"
            disableRipple
            onClick={handleClickLanguage}
            className={classNames(classes.menuButton)}
            aria-owns={anchorEl2 ? 'menu-language' : null}
          >
            <Typography className={classes.langIcon}>
              {t(`topBar.language.${selectedIndex || selectedLanguage || 'select'}`)}
            </Typography>
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            id="menu-language"
            anchorEl={anchorEl2}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={Boolean(anchorEl2)}
            onClose={handleCloseLanguage}
          >
            <MenuItem
              id={null}
              value={null}
              onClick={onChangeLng}
              selected={selectedIndex === null}
              className={classNames(classes.menuOption)}
            >
              {t('topBar.language.select')}
            </MenuItem>
            {
              availableLanguage.map((lang) => (
                <MenuItem
                  id={lang}
                  key={lang}
                  value={lang}
                  onClick={onChangeLng}
                  selected={selectedIndex === lang}
                  className={classNames(classes.menuOption)}
                >
                  {t(`topBar.language.${lang}`)}
                </MenuItem>
              ))
            }
          </Menu>

          <Typography noWrap align="center" variant={'body2'}>
            { user ? user.name : 'User' }
          </Typography>

          <div>
            <IconButton
              aria-haspopup="true"
              onClick={handleClickLogout}
              aria-owns={anchorEl3 ? 'menu-logout' : null}
            >
              <Avatar className={classes.avatar}>
                { user ? user.name.substring(0, 1) : 'U' }
              </Avatar>
            </IconButton>
            <Menu
              id="menu-logout"
              anchorEl={anchorEl3}
              open={Boolean(anchorEl3)}
              onClose={handleCloseLogout}
            >
              {user.isAdmin && <MenuItem onClick={() => navigate('/admin')}>
                <Icon style={{ marginRight: '10px', color: 'gray' }}>
                  { 'settings' }
                </Icon>{' '}
                {t('topBar.manage')}
              </MenuItem>}
              <MenuItem onClick={handleLogout.bind(this)}>
                <Icon style={{ marginRight: '10px', color: 'red' }}>
                  { 'power_settings_new' }
                </Icon>{' '}
                {t('topBar.logout')}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  open: PropTypes.bool, // eslint-disable-line
  classes: PropTypes.object, // eslint-disable-line
  title: PropTypes.string, // eslint-disable-line
  handleDrawerOpen: PropTypes.func, // eslint-disable-line
  user: PropTypes.object, // eslint-disable-line
  code: PropTypes.string, // eslint-disable-line
  t: PropTypes.func, // eslint-disable-line
  onChangeTheme: PropTypes.func, // eslint-disable-line
  selectedIndex: PropTypes.string, // eslint-disable-line
  i18n: PropTypes.object, // eslint-disable-line
  languageAutoSelection: PropTypes.bool,
  selectedCountryCode: PropTypes.string,
  selectedLanguage: PropTypes.string,
  setLanguage: PropTypes.func,
  setLanguageAutoSelectDefault: PropTypes.func,
  handleDrawerClose: PropTypes.func // eslint-disable-line
}

export default TopBar
