import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  titleContainer: {
    height: '30%',
    width: '100%',
    '& *': {
      textAlign: 'center'
    },
  },
  inputContainer: {
    height: '40%',
    width: '100%'
  },
  buttonContainer: {
    height: '30%',
    width: '100%'
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    fontSize: '16px',
    padding: `${theme.spacing(1.5)} ${theme.spacing(8)}px`,
    color: 'rgba(256, 256, 256, 0.8)',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 200,
    width: theme.spacing(35),
  }
}))