import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'

import { useQuery } from '@apollo/client'

import ComboBox from '../../components/ComboBox'
import DynamicInput from '../../components/DynamicInput'
import ButtonDefault from '../../components/ButtonDefault'
import CartWithTitle from '../../components/CardWithTitle'
import AllCompaniesQuery from '../../../graphql/get-all-companies'

const initialState = {
  companyId: '',
  code: '',
  description: '',
  urlImage: '',
  isCompleted: false,
  isNew: null,
}

export default function CompanyStep(props) {
  const { t } = useTranslation()

  const { data } = props
  const { companyId, code, description, urlImage } = data

  const [error, setError] = useState(false)
  const [allCompanies, setAllCompanies] = useState([])


  const { loading: loadingAllCompanies } = useQuery(AllCompaniesQuery, {
    onCompleted: ({ getAllCompanies }) => {
      setAllCompanies(getAllCompanies)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const isStepComplete = (obj) => {
    return ((obj.isNew === false && obj.companyId != null)
      || (obj.isNew === true && obj.code !== '' && obj.description !== '' && obj.urlImage !== ''))
  }

  const onCreateNew = () => {
    props.dataChanged({ ...initialState, isNew: true })
  }

  const onCompanyChange = (value) => {
    const data = {
      companyId: value, description: '', urlImage: '', isNew: false,
    }

    const isCompleted = isStepComplete(data)

    props.dataChanged({ ...data, isCompleted })
  }

  const setData = (fieldName, input) => {
    const isCompleted = isStepComplete({
      ...data, [fieldName]: input,
    })

    props.dataChanged({ [fieldName]: input, isCompleted })
  }

  return (
    <React.Fragment>
      <CartWithTitle title={t('labels.companyCardTitle')}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={4}>
            <ComboBox
              placeholder={t('labels.selectExistingCompany')}
              value={companyId}
              onChange={(value) => onCompanyChange(value)}
              data={allCompanies}
              disabled={false}
              loading={loadingAllCompanies}
              properties={{ label: 'description', value: 'companyId', id: 'companyId' }}
            />
          </Grid>          
          <Grid item xs={2}>
            <Typography>{t('labels.or')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <ButtonDefault
              text={t('labels.createNew')}
              onClick={onCreateNew}
              IconProps={Add}
            />
          </Grid>
        </Grid>
      </CartWithTitle>
      {data.isNew && <CartWithTitle title={t('labels.companyDetails')}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={3}>
            <Typography>
              {t('labels.code')}
            </Typography>
            <DynamicInput
              number={true}
              input={'code'}
              onInput={(value) => setData('code', value)}
              id={{
                placeholder: t('placeholders.code'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={code}
            />
          </Grid>
          <Grid item xs={7}>
            <Typography>
              {t('labels.description')}
            </Typography>
            <DynamicInput
              input={'description'}
              onInput={(value) => setData('description', value)}
              id={{
                placeholder: t('placeholders.description'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={description}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {t('labels.urlImage')}
            </Typography>
            <DynamicInput
              input={'urlImage'}
              maxLength={100}
              onInput={(value) => setData('urlImage', value)}
              id={{
                placeholder: t('placeholders.urlImage'),
              }}
              error={error}
              errorValidate={(newError) => setError(newError)}
              value={urlImage}
            />
          </Grid>
        </Grid>
      </CartWithTitle>}
    </React.Fragment>
  )
}