import { gql } from '@apollo/client'

export default gql`
  query getAllModules {
    getAllModules {
      moduleUid
      name
      description
      url
      iconColor
      iconName
      order
      createdAt
      updatedAt
      createdBy
      updatedBy
      routes {
        routeUid
        description
      }
    }
  }
`