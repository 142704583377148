import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Add from '@material-ui/icons/Add'
import Cancel from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import DynamicInput from '../../components/DynamicInput'
import ButtonDefault from '../../components/ButtonDefault'
import OptionButton from '../../components/OptionButton'

import SaveModuleMutation from '../../../graphql/save-module'

function ModuleEdit(props) {
  const [moduleUid, setModuleUid] = useState(props.moduleUid)
  const [name, setName] = useState(props.name)
  const [description, setDescription] = useState(props.description)
  const [url, setUrl] = useState(props.url)
  const [iconName, setIcon] = useState(props.iconName)
  const [iconColor, setColor] = useState(props.iconColor)
  const [order, setOrder] = useState(props.order)

  const [error, setError] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    setModuleUid(props.moduleUid)
    setName(props.name)
    setDescription(props.description)
    setUrl(props.url)
    setIcon(props.iconName)
    setColor(props.iconColor)
    setOrder(props.order)
  }, [props])

  const [saveModule, { loading }] = useMutation(SaveModuleMutation)

  const clearForm = () => {
    setModuleUid('')
    setName('')
    setDescription('')
    setUrl('')
    setIcon('')
    setColor('#000000')
    setOrder(null)
  }

  const onClickSaveButton = () => {
    const module = props.allModules.find((current) => current.name === name)
    if (module && moduleUid === '') {
      toastr.error(t('pages.module.errors.duplicated'))

      return
    }

    saveModule({
      variables: {
        module: {
          moduleUid,
          name,
          description,
          url,
          iconColor,
          iconName,
          order
        }
      }
    })
      .then(({ data: { mutateModule } }) => {
        clearForm()

        props.onAfterSave(mutateModule)

        toastr.success(t('toastr.success'))
      })
      .catch(() => {
        toastr.error(t('pages.module.errors.insert'))
      })
  }

  const onCancel = () => {
    clearForm()

    props.onAfterCancel()
  }

  const disableSaveButton = () => {
    return !(name !== '' && description !== '' && url !== '' && iconName !== '' && iconColor !== '')
  }

  return (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item xs={6}>
        <Typography>
          {t('labels.name')}
        </Typography>
        <DynamicInput
          input={'name'}
          onInput={(newName) => setName(newName)}
          id={{
            placeholder: t('placeholders.name'),
          }}
          error={error}
          errorValidate={(newError) => setError(newError)}
          value={name}
        />
      </Grid>

      <Grid item xs={6}>
        <Typography>
          {t('labels.description')}
        </Typography>
        <DynamicInput
          input={'description'}
          onInput={(newDescription) => setDescription(newDescription)}
          id={{
            placeholder: t('placeholders.description'),
          }}
          error={error}
          errorValidate={(newError) => setError(newError)}
          value={description}
        />
      </Grid>

      <Grid item xs={5}>
        <Typography>
          {t('labels.url')}
        </Typography>
        <DynamicInput
          input={'url'}
          onInput={(newUrl) => setUrl(newUrl)}
          id={{
            placeholder: t('placeholders.urlModule'),
          }}
          error={error}
          errorValidate={(newError) => setError(newError)}
          value={url}
        />
      </Grid>

      <Grid item xs={1}>
        <Typography>
          {t('labels.order')}
        </Typography>
        <DynamicInput
          allowNegative={true}
          number={true}
          input={'order'}
          maxLength={4}
          onInput={(newOrder) => setOrder(newOrder)}
          id={{
            placeholder: t('placeholders.order'),
          }}
          error={error}
          errorValidate={(newError) => setError(newError)}
          value={order}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography>
          {t('labels.iconName')}
        </Typography>
        <DynamicInput
          input={'iconName'}
          onInput={(newIcon) => setIcon(newIcon)}
          id={{
            placeholder: t('placeholders.iconName'),
          }}
          error={error}
          errorValidate={(newError) => setError(newError)}
          value={iconName}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography>
          {t('labels.iconColor')}
        </Typography>
        <DynamicInput
          input={'iconColor'}
          onInput={(newColor) => setColor(newColor)}
          id={{
            placeholder: t('placeholders.iconColor'),
          }}
          fieldType='color'
          error={error}
          errorValidate={(newError) => setError(newError)}
          value={iconColor}
        />
      </Grid>
      <Grid item xs={2}>
        <OptionButton
          title={'Preview'}
          icon={{ description: iconName, color: iconColor }}
          t={t}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={2}>
        <ButtonDefault
          disabled={disableSaveButton()}
          text={t('labels.save')}
          onClick={onClickSaveButton}
          loading={loading}
          IconProps={Add}
        />
      </Grid>
      <Grid item xs={2}>
        <ButtonDefault
          text={t('labels.cancel')}
          onClick={onCancel}
          loading={loading}
          IconProps={Cancel}
        />
      </Grid>
    </Grid>
  )
}

export default ModuleEdit