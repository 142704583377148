import { ApolloClient, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const createClient = (args) => {
  const { cache, accessToken } = args

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'access-control-allow-origin': '*',
      'Access-Control-Allow-Origin': '*',
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    }
  }))

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    headers: { 'Access-Control-Allow-Origin': '*' }
  })

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: cache ? cache : null,
  })
}
export default createClient
export {
  createClient,
}
