import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const MenuItemsClass = styled.div`
  a:link { text-decoration: none; }
  a:visited { text-decoration: none; }
  a:hover { text-decoration: none; }
  a:active { text-decoration: none; }

  .header-item{
    height: 53px;
  }

  .item{
    height: 53px;
    border-left: 4px solid #fff;
    background-color: #3c3b54;
    text-decoration: none;
  }

  .active{
    color: #ffffff !important;
    border-left: 4px solid #fff;
    background-color: #3c3b54;
  }

  .active-item{
    color: #ffffff;
    opacity: 1 !important;
  }

  .item--svg{
    opacity: 0.6
  }

 .navMenu{
    font-size: 14px;
    font-weight: 400;
    opacity: 0.6;
    color: #ffffff;
  }

  .icon{
    color: #9f9fad;
    width: 26px;
    padding-left: 4px;
  }
`


const isColapsed = ({ item, index, menuOpenList, handleOpenMenuList }) => {
  function onClickOpenMenuList() {
    handleOpenMenuList(menuOpenList, index)

  }

  if (item.colapse) {
    return (
      <div key={index}>
        <ListItem button className={`header-item ${menuOpenList[index] ? 'active' : ''}`} onClick={onClickOpenMenuList}>
          <ListItemIcon>
            {<item.icon className={`icon ${menuOpenList[index] ? 'active-item' : ''}`} />}
          </ListItemIcon>
          <ListItemText className={`navMenu ${menuOpenList[index] ? 'active-item' : ''}`}
            primary={item.title}
          />
          {menuOpenList[index] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {item.subMenuList.map((subItem, subItemIndex) => {
          return (
            <Collapse in={menuOpenList[index]} timeout="auto" unmountOnExit key={subItemIndex} className={'item'}>

              <ListItem button component={Link} to={subItem.path}>
                <ListItemText
                  primary={
                    <Typography className={`navMenu ${menuOpenList[index] ? 'active-item' : ''}`}>
                      {subItem.title}
                    </Typography>}
                />
              </ListItem>

            </Collapse>
          )
        })}
      </div>
    )
  }
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link to={item.path} key={index}>
      <ListItem button className={`header-item ${menuOpenList[index] ? 'active' : ''}`} onClick={onClickOpenMenuList}>
        <ListItemIcon>
          <item.icon className={`icon ${menuOpenList[index] ? 'active-item' : ''}`} />
        </ListItemIcon>
        <ListItemText className={`navMenu ${menuOpenList[index] ? 'active-item' : ''}`}
          primary={item.title} />
      </ListItem>
    </Link>
  )

}

export default function MenuItemsComponent({ menuList, menuOpenList, handleOpenMenuList }) {

  return (
    <MenuItemsClass>
      <List>
        {menuList.map((item, index) => (
          isColapsed({ item, index, menuOpenList, handleOpenMenuList })
        ))}
      </List>
    </MenuItemsClass>
  )
}