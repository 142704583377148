import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import LinearProgress from '@material-ui/core/LinearProgress'
import NumberFormat from 'react-number-format'

function DynamicInput(props) {
  if (props.number !== undefined && props.number === true && !props.disabled) {
    return (
      <FormControl style={{ width: '100%' }}>
        <NumberFormat
          allowNegative={props.allowNegative || false}
          InputLabelProps={props.InputLabelProps || { shrink: true, style: { fontWeight: 'bold' } }}
          label={props.id ? props.id.label : null}
          inputProps={{ maxLength: props.maxLength || 10 }}
          onValueChange={(newInput) => props.onInput(newInput.floatValue)}
          customInput={TextField}
          className={'input'}
          placeholder={props.id ? props.id.placeholder : null}
          style={{ width: '100%' }}
          value={props.value == null ? null : Number(props.value)}
          decimalSeparator={!!props.allowDecimal}
        />
        {props.loading ? <LinearProgress /> : <div />}
      </FormControl>
    )
  } else {
    return (
      <FormControl style={{ width: '100%' }}>
        <TextField
          disabled={props.disabled}
          InputLabelProps={
            props.InputLabelProps || { shrink: true, style: { fontWeight: 'bold' } }
          }
          inputProps={{ maxLength: props.maxLength || 35 }}
          type={props.fieldType ? props.fieldType : 'text'}
          label={props.id ? props.id.label : null}
          onChange={(newInput) => props.onInput(newInput.target.value)}
          placeholder={props.id ? props.id.placeholder : null}
          className={'input'}
          style={{ width: '100%' }}
          value={props.value}
        />
        {props.loading ? <LinearProgress /> : <div />}
      </FormControl>
    )
  }
}

export default DynamicInput