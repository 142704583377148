import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'

import { useQuery } from '@apollo/client'

import CartWithTitle from '../../components/CardWithTitle'
import PermissionSelector from '../../components/PermissionSelector'

import companyCountryAccessQuery from '../../../graphql/get-company-country-access'
import allModulesQuery from '../../../graphql/get-all-modules'

export default function ModuleStep(props) {
  const { t } = useTranslation()

  const getData = (step, fieldName) => {
    const values = props.getFormData(step)

    if (!values.isNew) {
      return { label: values[fieldName].label, id: values[fieldName].value }
    }

    return { label: values.description, id: values.code }
  }
  
  const getCompanyData = () => {
    return getData('company', 'companyId')
  }

  const getCountryData = () => {
    return getData('country', 'countryId')
  }

  const [isEnabled, setIsEnabled] = useState(null)
  const [routeAccess, setRouteAccess] = useState([])
  const [allModules, setAllModules] = useState([])

  useEffect(() => {
    props.dataChanged({ isCompleted: true })
  }, [])

  const { loading } = useQuery(companyCountryAccessQuery, {
    variables: { companyId: getCompanyData().id, countryId: getCountryData().id },
    fetchPolicy: 'network-only',
    onCompleted: ({ getCompanyCountryAccess }) => {
      const [existing] = getCompanyCountryAccess

      const formattedRouteAccess = getCompanyCountryAccess.map((access) => ({
        role: access.roleName,
        routes: access.modules
          .map((module) => module.routes
            .map((route) => ({
              id: module.name, label: route.description, value: route.routeUid
            }))).flat()
      }))

      setRouteAccess(formattedRouteAccess)

      setIsEnabled(existing ? existing.isEnabled : null)

      props.dataChanged({
        routeAccess: formattedRouteAccess,
        isEnabled: existing ? existing.isEnabled : null,
        isCompleted: true,
      })
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const { loadingAllModules } = useQuery(allModulesQuery, {
    variables: {},
    fetchPolicy: 'network-only',
    onCompleted: ({ getAllModules }) => {
      setAllModules(getAllModules)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const onPermissionChanged = (values) => {
    setRouteAccess(values)

    props.dataChanged({ isCompleted: true, routeAccess: values })
  }

  const onIsEnabledChanged = () => {
    setIsEnabled(!isEnabled)

    props.dataChanged({ isEnabled: !isEnabled })
  }

  const getCompanyCountryLabel = () => {
    const enabled = isEnabled ? t('labels.isEnabled') : t('labels.isDisabled')

    return `${getCompanyData().label} > ${getCountryData().label} ${enabled}`
  }

  const isLoading = loading || loadingAllModules

  return (
    <CartWithTitle title={t('labels.permissions')}>
      {!isLoading && isEnabled !== null && <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch onChange={onIsEnabledChanged} checked={isEnabled} />}
            label={getCompanyCountryLabel()}
            labelPlacement="start" />
          <Divider orientation='horizontal' variant='fullWidth'/>
        </Grid>
      </Grid>}
      {isLoading && <CircularProgress />}
      {!isLoading && <PermissionSelector
        data={routeAccess}
        modules={allModules}
        onChange={onPermissionChanged} />}
    </CartWithTitle>
  )
}