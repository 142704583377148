import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { Button, Icon, CircularProgress } from '@material-ui/core'
import { useLazyQuery } from '@apollo/client'
import useStyles from './styles'
import TopBar from '../../containers/TopBar'
import ModuleTab from '../../containers/ModuleTab'

import GetXAppToken from '../../../graphql/get-x-app-token'
import GetCompanyAccess from '../../../graphql/get-company-access'
import GetCountryAccess from '../../../graphql/get-country-access'
import GetModuleAccess from '../../../graphql/get-module-acess'

import { Context } from '../../context'

import { fallbackLng, languageResolver, saveLanguage } from '../../../enum/languages-whitelist'
import { useTranslation } from 'react-i18next'

const openPanel = (open) => ({
  width: open ? 'calc(100% - 216px)' : '96px'
})

const baseTranslationPath = 'pages.modules.panels.'

const TAB_CONFIGS = {
  COMPANIES: {
    id: 'companies',
    nextTabId: 'countries',
    icon: 'business',
    translationPath: `${baseTranslationPath}company`,
    nextQuery: GetCountryAccess,
    nextQueryName: 'getCountryAccess',
  },
  COUNTRIES: {
    id: 'countries',
    nextTabId: 'modules',
    icon: 'language',
    translationPath: `${baseTranslationPath}country`,
    nextQuery: GetModuleAccess,
    nextQueryName: 'getAccessModule'
  },
  MODULES: {
    id: 'modules',
    nextTabId: 'modules',
    icon: 'apps',
    translationPath: `${baseTranslationPath}modules`,
    nextQuery: undefined,
    queryName: 'getAccessModule',
  }
}

function Modules(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [state, setState] = useState({
    companiesTabOpen: true,
    countriesTabOpen: false,
    modulesTabOpen: false,
    companiesSelection: null,
    countriesSelection: null,
    modulesSelection: null,
    companiesLoading: true,
    countriesLoading: false,
    modulesLoading: false,
    language: null,
    languageAutoSelectDefault: true,
    countries: [],
    companies: [],
    modules: [],
    selectedLanguage: null,
    selectedCountryCode: null,
    currentTab: TAB_CONFIGS.COMPANIES,
    isLoading: false,
  })

  const [userState] = useContext(Context)

  const { currentUser } = userState
  const { user } = currentUser

  const [openTab, setOpenTab] = useState(TAB_CONFIGS.COMPANIES.id)
  const [loadingTab, setLoadingTab] = useState(TAB_CONFIGS.COMPANIES.id)
  const [selectedOptions, setSelectedOptions] = useState({
    [TAB_CONFIGS.COMPANIES.id]: {},
    [TAB_CONFIGS.COUNTRIES.id]: {},
    [TAB_CONFIGS.MODULES.id]: {},
  })
  const [tokenAccess, setTokenAccess] = useState(null)
  const [language, setCurrentLanguage] = useState(fallbackLng())
  
  const formatData = (data) => {
    return data.map((image) => ({
      ...image,
      title: image.description,
      image: {
        link: image.urlImage,
        alt: image.description
      },
    }))
  }

  const formatModules = (modules) => {
    return modules.map((modul) => ({
      ...modul,
      title: `mock.modules.${modul.name}`,
      description: modul.description,
      icon: {
        description: modul.iconName,
        color: modul.iconColor
      }
    }))
  }

  const [getToken] = useLazyQuery(GetXAppToken, {
    variables: {
      roles: user.profile,
      moduleUid: selectedOptions[TAB_CONFIGS.MODULES.id].moduleUid,
      countryCode: selectedOptions[TAB_CONFIGS.COUNTRIES.id].countryCode,
      countryId: selectedOptions[TAB_CONFIGS.COUNTRIES.id].countryId,
      companyId: selectedOptions[TAB_CONFIGS.COMPANIES.id].companyId,
      language,
    },
    onCompleted: ({ getXAppToken: { token } }) => {
      setTokenAccess(token)
      setState({ ...state, isLoading: false })
      setLoadingTab(null)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const [getCompanies] = useLazyQuery(GetCompanyAccess, {
    variables: { roles: user.profile },
    onCompleted: ({ getCompanyAccess }) => {
      setState({
        ...state, companies: formatData(getCompanyAccess), companiesLoading: false,
      })
      setLoadingTab(null)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const [getCountries] = useLazyQuery(GetCountryAccess, {
    onCompleted: ({ getCountryAccess }) => {
      setState({
        ...state, countries: formatData(getCountryAccess), countriesLoading: false,
      })
      setLoadingTab(null)
    },
    onError: () => toastr.error(t('toastr.error'))
  })
  
  const [getModules] = useLazyQuery(GetModuleAccess, {
    onCompleted: ({ getAccessModule }) => {
      setState({
        ...state, modules: formatModules(getAccessModule),
      })
      setLoadingTab(null)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  useEffect(() => { getCompanies() }, [])

  const handleTabClick = (clickedTab) => {
    setOpenTab(clickedTab.id)
  }

  const updateData = (tab, option) => {
    const variables = {
      roles: user.profile,
      companyId: (option || {}).companyId ? option.companyId : selectedOptions[TAB_CONFIGS.COMPANIES.id].companyId,
      countryId: (option || {}).countryId ? option.countryId : '',
    }

    if (tab === TAB_CONFIGS.COMPANIES) {
      getCountries({ variables })
    }

    if (tab === TAB_CONFIGS.COUNTRIES) {
      const { countryCode } = state

      setState({ selectedCountryCode: countryCode })

      const defaultCountryLanguage = `${language}`.replace(/^null|undefined$/, 'es')
        .split('-').shift().toLowerCase()
  
      if (state.languageAutoSelectDefault) {
        const updatedLang = languageResolver(defaultCountryLanguage, countryCode)
  
        setLanguage(updatedLang)
      } else {
        const fbLang = fallbackLng(defaultCountryLanguage)
        const baseLang = fbLang.split('-').shift()
        const updatedLang = languageResolver(baseLang, countryCode)
  
        setLanguage(updatedLang)
      }

      getModules({ variables })
    }

    if (tab === TAB_CONFIGS.MODULES) {
      setState({ ...state, isLoading: true })
      getToken()
    }
  }

  const handleOptionClicked = (tab, option) => {
    const { id, nextTabId } = tab

    setSelectedOptions({ ...selectedOptions, [id]: option })
    setLoadingTab(nextTabId)
    setOpenTab(nextTabId)
    updateData(tab, option)
  }

  const setLanguage = (selected) => {
    const selectedLanguage = selected.split('-').shift()
    setCurrentLanguage(selected)
    setState({ ...state, selectedLanguage })
    saveLanguage(selected)
  }

  const setLanguageAutoSelectDefault = (value) => {
    setState({ ...state, languageAutoSelectDefault: value })
  }

  const enableAccess = () => {
    return JSON.stringify(selectedOptions[TAB_CONFIGS.COMPANIES.id]) !== '{}'
      && JSON.stringify(selectedOptions[TAB_CONFIGS.COUNTRIES.id]) !== '{}'
      && JSON.stringify(selectedOptions[TAB_CONFIGS.MODULES.id]) !== '{}'
  }

  const handleAccess = (event) => {
    event.preventDefault()

    if (tokenAccess) {
      const uri = `${process.env.REACT_APP_COGNITO_CALLBACK_URL}${selectedOptions[TAB_CONFIGS.MODULES.id].url}?token=${tokenAccess}`
      window.location.href = uri
    }
  }

  return (
    <div className={classes.root}>
      <TopBar
        setLanguage={setLanguage.bind(this)}
        setLanguageAutoSelectDefault={setLanguageAutoSelectDefault.bind(this)}
        selectedLanguage={state.selectedLanguage}
        setAuthorization={props.setAuthorization}
        currentUser={currentUser}
        selectedCountryCode={state.selectedCountryCode}
        title={t('topBar.moduleTitle')}
        classes={classes}
      />
      <div className={classes.toolbarMargin} />
      <main className={classes.content}>
        {
          Object.values(TAB_CONFIGS).map((tab, index) => {
            const { id } = tab
            const isOpen = openTab === id
            return (
              <div className={classes.panelContainer} style={openPanel(isOpen)}>
                <ModuleTab
                  key={`${id}_${index}`}
                  open={isOpen}
                  tabClickHandler={handleTabClick.bind(this, tab)}
                  config={tab}
                  t={t}
                  handleOptionClicked={handleOptionClicked.bind(this, tab)}
                  selectedOption={selectedOptions[id]}
                  loading={loadingTab === id}
                  options={state[id]}/>
              </div>
            )
          })
        }
        {
          (enableAccess()) ?
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonEntry}
              onClick={handleAccess.bind(this)}
            >
              <span className={classes.spanText}>{t('pages.modules.button')}</span>
              { (!tokenAccess || state.isLoading) ? (
                <CircularProgress className={classes.rightIcon} />
              ) : (
                <Icon className={classes.rightIcon}>{'send'}</Icon>
              )}
            </Button>
            :
            <div />
        }
      </main>
    </div>
  )
}

Modules.propTypes = {
  classes: PropTypes.object, // eslint-disable-line
  selectedLanguage: PropTypes.string,
  languageAutoSelectDefault: PropTypes.bool,
  t: PropTypes.func // eslint-disable-line
}

export default Modules
