import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { availableLanguage, fallbackLng } from './enum/languages-whitelist'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    availableLanguage,
    backend: {
      loadPath: '/locales/{{lng}}.json'
    },
    fallbackLng: fallbackLng(),
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    react: {
      wait: true,
      useSuspense: false,
    }
  })

export default i18n
