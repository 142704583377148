import React, { useEffect, useState } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TabPanel from '../TabPanel'
import OptionButton from '../../components/OptionButton'

const useStyles = makeStyles((theme) => ({
  gridItem: {
    ...theme.utils.center,
    height: theme.spacing(24)
  },
}))

export default function ModuleTab(props) {
  const { open, config: { icon, translationPath }, selectedOption } = props

  const classes = useStyles()
  const { t } = useTranslation()

  const [state, setState] = useState({
    optionControls: {},
    options: props.options,
    received: false,
  })

  useEffect(() => {
    if(props.options && !state.received) {
      const keys = props.options.map((_, index) => `option${index}`)

      setState({
        ...state,
        options: props.options,
        received: props.options.length > 0,
        optionControls: {
          ...state.optionControls,
          ...keys.reduce((optionControls, key) => ({ ...optionControls, [key]: false }), {})
        }
      })
    }
  }, [props.options, state.received])

  const handleTabClick = () => {
    props.tabClickHandler(props.config.id)
  }

  const handleOptionClick = (key, index) => {
    const optionControls = Object.keys(state.optionControls)
      .reduce((newOptions, option) => ({
        ...newOptions,
        [option]: option === key
      }), {})

    setState({
      ...state,
      index,
      optionControls
    })

    props.handleOptionClicked(state.options[index])
  }

  return (
    <TabPanel 
      open={open} 
      icon={icon}
      openCallback={handleTabClick.bind(this)}
      t={t}
      parentTranslationPath={translationPath}
      preview={selectedOption}>
      {
        state.received
          ? props.options.map((option, index) => {
            const key = `option${index}`
            const length = state.options.length
            return (
              <Grid key={key} item 
                lg={length > 4 ? 3 : (12 / length)} 
                md={length > 3 ? 4 : (12 / length)} 
                className={classes.gridItem}
              >
                <OptionButton
                  title={option.title}
                  image={option.image}
                  icon={option.icon}
                  selected={state.optionControls[key]}
                  clickCallback={handleOptionClick.bind(this, key, index)}
                  t={t}/>
              </Grid>
            )
          })
          : props.loading && (<CircularProgress />)
      }
    </TabPanel>
  )
}
