import { gql } from '@apollo/client'

export default gql`
  query getAllCountries {
    getAllCountries {
      countryId
      description
      urlImage
    }
  }
`
