import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  main: {
    height: '100%',
    width: '100%'
  },
  tabCard: {
    boxShadow: '0px 0px',
    height: '100%',
    borderRadius: theme.spacing.unit,
    width: theme.spacing(11.5),
    float: 'left',
    position: 'relative',
    margin: `0px ${theme.spacing(0.25)}px`,
  },
  title: {
    margin: `${theme.spacing.unit}px 0px`,
    fontFamily: '"Natura Sans", Roboto, Helvetica, Arial, sans-serif',
    fontSize: '12px',
    width: '100%',
    '&.active': { 
      fontWeight: 'bold'
    },
    '&.small': {
      fontSize: '12px'
    },
    '&.large': {
      fontSize: '22px',
      fontWeight: 'bold'
    },
  },
  tabCardTop: {
    ...theme.utils.center,
    height: '20%',
    width: '100%',
    position: 'absolute',
    top: 0
  },
  tabCardBody: {
    ...theme.utils.center,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0
  },
  panel: {
    height: '100%',
    width: `calc(100% - ${theme.spacing(12)}px)`,
    float: 'left',
  },
  panelTitle: {
    ...theme.utils.center,
    height: '20%',
    width: '100%',
  },
  panelBody: {
    ...theme.utils.center,
    height: '80%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  fill: {
    height: '100%',
    width: '100%',
    display: 'block'
  },
  icon: {
    color: theme.palette.grey[700],
    fontSize: theme.spacing(2)
  },
  previewContainer: {
    ...theme.utils.center,
    height: theme.spacing(8),
    width: theme.spacing(8),
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderStyle: 'solid',
    borderWidth: theme.spacing(0.5),
    borderRadius: theme.spacing(2),
    borderColor: 'transparent',
  }
}))
