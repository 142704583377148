import React, { useEffect, useState, useContext } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'

import routes from '../routes'

import GetUserProfileQuery from '../graphql/get-user-profile'

import { Context } from './context'
import { FETCH_USER } from './containers/Profile/profile-actions'

export default function Main(props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [userProfile, dispatch] = useContext(Context)

  const [authorized, setAuthorized] = useState(true)

  const { currentUser } = props

  const [getUserProfileQuery]
  = useLazyQuery(GetUserProfileQuery, {
    onCompleted: ({ getUserProfile }) => {
      currentUser.user.isAdmin = getUserProfile.isAdmin

      dispatch({
        type: FETCH_USER,
        currentUser,
      })
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  useEffect(() => {
    getUserProfileQuery({
      variables: { roles: currentUser.user.profile },
    })
  }, [getUserProfileQuery, props])

  useEffect(() => {
    if(!authorized) {
      navigate('/login')
    }
  }, [authorized])

  const setAuthorization = (isAuthorized) => {
    setAuthorized(() => {
      if(isAuthorized) {
        navigate('/')
      } else {
        navigate('/login')
      }
    })
  }

  const redirect = (route) => {
    navigate(route.path)
  }

  const filterRestrictedRoute = (route) => {
    const { currentUser: { user } } = userProfile

    if (user.isAdmin) {
      return true
    }

    return !route.isRestricted
  }

  return (
    <React.Fragment>
      {!userProfile.currentUser && <CircularProgress style={{ width: '25px', height: '25px' }} />}
      {userProfile.currentUser && <Routes>
        {routes.filter(filterRestrictedRoute).map((route, i) => 
          (<Route
            key={i}
            path={route.path}
            element={
              <route.component
                {...props}
                routes={route.routes}
                authorized={authorized}
                setAuthorization={setAuthorization}
                currentUser={currentUser}
                redirect={redirect}
              />
            }
          />)
        )}
      </Routes>}
    </React.Fragment>
  )
}
