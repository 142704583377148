import { gql } from '@apollo/client'

export default gql`
  query getCompanyCountryAccess(
    $companyId: Int!
    $countryId: Int!
    ) {
      getCompanyCountryAccess(
        companyId: $companyId,
        countryId: $countryId
      ) {
        companyId
        countryId
        roleName
        roleUid
        isAdmin
        isEnabled
        modules {
          moduleUid
          name
          description
          url
          iconName
          iconColor
          createdBy
          createdAt
          updatedBy
          updatedAt
          routes {
            routeUid
            description
          }
      }
    }
  }
`
