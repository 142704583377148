import { FETCH_USER } from './profile-actions'

export const initialProfileState = null

export const profileReducer = (state, action) => {
  switch (action.type) {
  case FETCH_USER:
    return action.currentUser
  default:
    return state
  }
}