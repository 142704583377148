import React from 'react'
import { ButtonBase, Icon, Typography } from '@material-ui/core'
import classNames from 'classnames'
import useStyles from './styles'

export default function OptionButton (props) {
  const classes = useStyles()
  const { title, image, icon, selected, t, clickCallback } = props

  return (<ButtonBase 
    onClick={clickCallback} 
    className={classNames(classes.container)}
    disableRipple={true}>
    <div className={classNames(classes.graphicContainer)}>
      {
        (image && (
          <div className={classNames(classes.imageContainer)}>
            <img src={image.link} alt={t(image.alt)} width={'100%'} height={'auto'} />
          </div>
        )) || (icon && (
          <div style={{ backgroundColor: selected && icon.color }}
            className={classNames(classes.outerIconContainer)}>
            <div style={{ borderColor: selected ? 'transparent' : icon.color }}
              className={classNames(classes.iconContainer)}>
              <Icon className={classNames(classes.icon, selected && 'active')}>{ icon.description }</Icon>
            </div>
          </div>
        ))
      }
    </div>
    <Typography className={classNames(classes.typography, selected && 'active')}>
      { t(title) }
    </Typography>
  </ButtonBase>)
}