import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import CircularProgress from '@material-ui/core/CircularProgress'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

export default function Wizard(props) {
  const { steps, loading } = props

  const initialStepsState = steps.reduce((prev, curr) => {
    return { ...prev, [curr.name]: { isCompleted: false } }
  }, {})

  const classes = useStyles()
  const { t } = useTranslation()
  const [activeStep, setCurrentStep] = useState(props.activeStep)
  const [stepsData, setStepsData] = useState(initialStepsState)

  const handleNext = () => {
    if (isLastStep()) {
      props.onSubmit(stepsData)
    }

    setCurrentStep((currentStep) => currentStep + 1)
  }

  const handleBack = () => {
    setCurrentStep((currentStep) => currentStep - 1)
  }

  const getCurrentStepName = () => {
    return steps[activeStep].name
  }

  const getCurrentStepComponent = () => {
    const step = steps[activeStep]

    if (!step) return null

    return step.component
  }
  
  const getCurrentStepData = () => {
    return stepsData[getCurrentStepName()]
  }

  const isStepCompleted = () => {
    const stepState = getCurrentStepData()

    return stepState.isCompleted
  }

  const handleReset = () => {
    setStepsData(initialStepsState)
    setCurrentStep(0)
  }

  const onDataChange = (value) => {
    const stepName = getCurrentStepName()

    const newData = { ...stepsData, [stepName]: { ...stepsData[stepName], ...value } }

    setStepsData(newData)
  }

  const onGetFormData = (stepName) => {
    return stepsData[stepName]
  }

  const isLastStep = () => {
    return activeStep === steps.length -1
  }

  const StepComponent = getCurrentStepComponent()

  return (
    <div>
      <Stepper activeStep={activeStep}>
        {steps.map((step) => {
          return (
            <Step key={step.label}>
              <StepLabel>{t(step.label)}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      
      {activeStep === steps.length ? (
        <Grid container spacing={3} justifyContent="space-between">
          {loading && <CircularProgress />}
          {!loading && <React.Fragment><Grid item xs={12} >
            <Typography>{t('labels.finishedMessage')}</Typography>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button onClick={handleReset}>{'Reset'}</Button>
          </Grid></React.Fragment>}
        </Grid>
      ) : (<Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} >
          {StepComponent && 
          <StepComponent
            data={stepsData[getCurrentStepName()]}
            dataChanged={onDataChange}
            getFormData={onGetFormData}
          />}
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={10}></Grid>
        <Grid item xs={9}></Grid>
        <Grid item xs={3} >
          <Button disabled={activeStep === 0} onClick={handleBack}>
            {t('labels.backButton')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
            disabled={!isStepCompleted()}
          >
            {activeStep === steps.length - 1 ? t('labels.finishButton') : t('labels.nextButton')}
          </Button>
        </Grid>
      </Grid>
      )}
    </div>
  )
}
