/* eslint-disable */
import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import AppsIcon from '@material-ui/icons/Apps'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import SettingsIcon from '@material-ui/icons/Settings'

import AppBar from '../../components/AppBar'
import Menu from '../../components/Menu'
import MenuItems from '../../components/Menu/MenuItems'
import Wizard from '../../pages/Wizard'
import ModuleList from '../../pages/Module'
import RouteList from '../../pages/Route'

const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginLeft: drawerWidth,
    backgroundColor: '#f6f6f6',
    padding: theme.spacing(3),
  },
  mainShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  mainStatic: {
    marginLeft: '70px',
    width: 'calc(100% - 70px)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  root: {
    display: 'flex',
    backgroundColor: '#f6f6f6',
    backgroundSize: 'cover',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '58px'
  },
}))

export default function MainContainer({ currentUser, children }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const menuList = [
    {
      title: t('titles.menus.manage'),
      colapse: false,
      icon: SettingsIcon,
      component: Wizard,
      path: '/admin/wizard',
    }, {
      title: t('titles.menus.modules'),
      colapse: false,
      icon: AppsIcon,
      path: '/admin/modules',
      component: ModuleList,
    }, {
      title: t('titles.menus.routes'),
      colapse: false,
      icon: AccountTreeIcon,
      path: '/admin/routes',
      component: RouteList,
    }
  ]

  const [menuOpen, setMenuOpen] = useState(false)
  const [menuNotificationOpen, setMenuNotificationOpen] = useState(false)
  const [anchorNotificationOpen, setAnchorNotificationOpen] = useState(null)
  const [menuOpenList, setMenuOpenList] = useState(Array(menuList.length).fill(false))

  const pages = []

  function handleDrawerToggle() {
    setMenuOpen(!menuOpen)
  }

  function handleDrawerNotificationToggle(event) {
    setAnchorNotificationOpen(event.currentTarget)
    setMenuNotificationOpen(!menuNotificationOpen)
  }

  function handleOpenMenuList(openList, index) {
    setMenuOpenList((prevState) => {
      const newState = [...prevState]
      newState[index] = !openList[index]

      return newState
    })
    setMenuOpen(true)
  }

  return (
    <div className={classNames(classes.content, menuOpen ? classes.mainShift : classes.mainStatic)}>
      <AppBar
        currentUser={currentUser}
        handleDrawerToggle={handleDrawerToggle}
        handleDrawerNotificationToggle={handleDrawerNotificationToggle}
        menuOpen={menuOpen}
        menuNotificationOpen={menuNotificationOpen}
        pages={pages} />
      <Menu
        handleDrawerToggle={handleDrawerToggle}
        menuOpen={menuOpen}
        setMenuOpenList={setMenuOpenList}>
        <MenuItems
          menuList={menuList}
          menuOpenList={menuOpenList}
          handleOpenMenuList={handleOpenMenuList}
        />
      </Menu>
      <div className={classes.toolbar} />
      {children}
    </div>
  )
}
