import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const BarFixStyle = styled.div`

    display:flex;
    max-width:385px;
    width: 68%;
    margin: 0 auto;
    margin-top: -45px;
    text-align: center;

    .L, .R{
        width: 22px;
        height: 15px;
        position: relative;
        background-color: #00EB91;
        margin-top: 5px;
    }

    .L:before {
        content: "";
        display: block;
        position: relative;
        border-top-right-radius: 220px;
        width: 23px;
        height: 15px;
        background-color: white;
    }

    .R:before {
        content: "";
        display: block;
        position: relative;
        border-top-left-radius: 220px;
        left: -1px;
        width: 23px;
        height: 15px;
        background-color: white;
        
    }

    .text{
        color: #fff;
        font-size: 12px;
    }

    .labelSystem{
        width:  94%;
        padding: 0 3% 0 3%;
        background-color: #00EB91;
        padding-top: 7px;
        border-radius: 0px 0px 18px 18px;
        -moz-border-radius: 0px 0px 18px 18px;
        -webkit-border-radius: 0px 0px 18px 18px;
    }

    @media (max-width: 820px) 
    {
      .L, .R{ visibility: hidden }

      &{
        width: 100%;
        position: absolute;
        top: -100px;
      }
    }

`

const BarFix = ({ children }) => {
  return (
    <BarFixStyle className="bar">
      <div className="L"></div>
      <div className="labelSystem">
        <div>
          <Typography className='text' gutterBottom>
            {children}
          </Typography>
        </div>
      </div>
      <div className="R"></div>
    </BarFixStyle>
  )
}

export default BarFix
