import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  imageOverlay: { 
    // ...theme.utils.center,
    position: 'absolute', 
    width: '100%', 
    height: '100%',
    top: 0,
  },
  imageContainer: { 
    overflow: 'hidden', 
    height: '100%', 
    position: 'relative',
    '& > img': {
      marginLeft: '-50%'
    }
  },
  mobileMain: {
    overflow: 'hidden', 
    height: '100%', 
    position: 'relative',
    '& > img': {
      marginLeft: '-50%'
    }
  },
  mobileBackground: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0
  },
  mobileContent: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0
  },
  mobileHeader: {
    ...theme.utils.center,
    height: '20%'
  },
  mobileCardHolder: {
    padding: theme.spacing(4),
    height: '80%',
  },
  mobileCard: {
    height: '100%'
  }
}))