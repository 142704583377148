import React from 'react'
import { ApolloProvider, InMemoryCache } from '@apollo/client'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import ToastrProvider from 'react-redux-toastr'
import { createClient } from '../apollo-connect'
import Main from './main'

import theme from './theme'

export default function App(props) {
  const { currentUser } = props

  const toastrTimeout = 4000

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={createClient({
        accessToken: currentUser.idToken,
        cache: new InMemoryCache({
          addTypename: false
        })
      })}>
        <CssBaseline />
        <Main theme={theme} {...props}/>
        <ToastrProvider
          timeOut={toastrTimeout}
          newestOnTop={false}
          preventDuplicates
          position="bottom-center"
          transitionIn="bounceIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick />
      </ApolloProvider>
    </ThemeProvider>
  )
}

