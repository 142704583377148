import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ModuleForm from '../../containers/ModuleForm'
import PageBase from '../PageBase'
import MainContainer from '../../containers/Admin'
import DataGrid from '../../components/DataGrid'

import GetAllModulesQuery from '../../../graphql/get-all-modules'
import DeleteModuleMutation from '../../../graphql/delete-module'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '0 0 0 25px',
    margin: '-40px'
  },
  background: {
    backgroundColor: '#f6f6f6',
    backgroundSize: 'cover',
  },
  button: {
    color: '#f6f6f6',
  },
  label: {
    'font-size': '22px',
    'font-family': '\'Natura Sans\', Roboto, Helvetica, Arial, sans-serif\'',
    'font-weight': '500',
  },
  padding: '20px 0 41px 0',
  Grid: {
    padding: '20px',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  paper: {
    border: '10px'
  },
  control: {
    padding: theme.spacing(2),
  },
}))

const initialState = {
  moduleUid: '',
  name: '',
  description: '',
  url: '',
  iconColor: '#000000',
  iconName: '',
}

function ModuleList(props) {
  const [allModules, setAllModules] = useState([])
  const [expanded, setExpanded] = React.useState('list')
  const [editingModule, setEditingModule] = useState(initialState)

  const handleActivePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const classes = useStyles()
  const { t } = useTranslation()

  const { loading: loadingQuery } = useQuery(GetAllModulesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAllModules }) => {
      setAllModules(getAllModules)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const [deleteMutation, { loading: loadingDelete }]
    = useMutation(DeleteModuleMutation)

  const columns = [
    { id: 'name', numeric: false, disablePadding: false, label: t('labels.name'), isNumber: false, toEdit: true },
    { id: 'description', numeric: false, disablePadding: false, label: t('labels.description'), isNumber: false, toEdit: true },
    { id: 'url', numeric: false, disablePadding: false, label: t('labels.url'), isNumber: false, toEdit: true },
    { id: 'iconColor', fieldType: 'color', numeric: false, disablePadding: false, label: t('labels.color'), isNumber: false, toEdit: true },
    { id: 'iconName', numeric: false, disablePadding: false, label: t('labels.iconName'), isNumber: false, toEdit: true },
    { id: 'order', numeric: false, disablePadding: false, label: t('labels.order'), isNumber: true, toEdit: true },
    { id: 'updatedAt', numeric: false, disablePadding: false, label: t('labels.updatedAt'), isNumber: false, toEdit: true },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: t('labels.updatedBy'), isNumber: false, toEdit: true },
  ]

  const onEdit = (row) => {
    const selectedModule = allModules.find((module) => module.moduleUid === row.moduleUid)

    setExpanded('edit')
    setEditingModule(selectedModule)
  }

  const onRemove = (row) => {
    deleteMutation({ variables: { moduleUid: row.moduleUid } })
      .then(() => {
        setAllModules(allModules.filter((item) => item.moduleUid !== row.moduleUid))

        setEditingModule(initialState)

        toastr.success(t('toastr.success'))
      })
      .catch(() => {
        toastr.error(t('pages.module.errors.delete'))
      })
  }

  const onAfterSave = (row) => {
    setAllModules([
      ...allModules.filter((module) => module.moduleUid !== row.moduleUid), row
    ])

    setEditingModule(initialState)

    setExpanded('list')
  }

  const onAfterCancel = () => {
    setEditingModule(initialState)

    setExpanded('list')
  }

  const renderTable = () => {
    return <DataGrid
      onRemove={onRemove}
      columns={columns}
      rows={allModules}
      loading={loadingQuery || loadingDelete}
      onEdit={onEdit}
    />
  }

  return (
    <MainContainer {...props}>
      <PageBase {...props} title={t('titles.modulesPage')}>
        <Accordion expanded={expanded === 'edit'} onChange={handleActivePanel('edit')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('labels.createNew')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ModuleForm allModules={allModules} {...editingModule} onAfterCancel={onAfterCancel} onAfterSave={onAfterSave} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'list'} onChange={handleActivePanel('list')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('labels.records')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(loadingQuery || loadingDelete) ?
              <div className={classes.progress}>
                <CircularProgress />
              </div> : renderTable()}
          </AccordionDetails>
        </Accordion>
      </PageBase>
    </MainContainer>
  )
}

export default ModuleList