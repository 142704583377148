import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise'
import multi from 'redux-multi'
import reduxLogger from 'redux-logger'
import { createBrowserHistory } from 'history'

import reducers from './reducer'

export const history = createBrowserHistory ? createBrowserHistory() : []

const initialState = {}
const enhancers = []
const middleware = [
  multi,
  thunk,
  promise,
  reduxLogger,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return reducers(initialState, action)
  }

  return reducers(state, action)
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store
