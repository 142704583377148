import React, { useReducer, createContext } from 'react'
import combineReducer from './combineReducer'

import { profileReducer, initialProfileState } from '../containers/Profile/profile-reducer'

export const Context = createContext([])

export const Provider = ({ children }) => {
  const [state, dispatch] = combineReducer({
    currentUser: useReducer(profileReducer, initialProfileState),
  })

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
}
