import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import MenuUser from './menuUser'
import IconButton from '@material-ui/core/IconButton'
import View from '@material-ui/icons/ViewModule'

const PerfilBox = styled.div`

margin-top: -1px;
  
`

function Profile(props) {

  const { currentUser } = props

  return (
    <PerfilBox>
      <div style={{ display: 'inline-block', marginRight: '4px', marginLeft: '4px' }}>
        <a href={process.env.REACT_APP_COGNITO_CALLBACK_URL}>
          <IconButton style={{ padding: '0px' }} >
            <View style={{ color: '#bcbccb' }} />
          </IconButton>
        </a>
      </div>

      <div style={{ display: 'inline-block', marginLeft: '9px' }}>
        <MenuUser
          currentUser={currentUser}
          userName={currentUser.user.name} />
      </div>

    </PerfilBox>
  )
}

Profile.propTypes = {
  header: propTypes.any
}


export default Profile
