import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Add from '@material-ui/icons/Add'
import Cancel from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'

import ComboBox from '../../components/ComboBox'
import DynamicInput from '../../components/DynamicInput'
import ButtonDefault from '../../components/ButtonDefault'

import GetAllModulesQuery from '../../../graphql/get-all-modules'
import SaveRouteMutation from '../../../graphql/save-route'

function RouteEdit(props) {
  const [routeUid, setRouteUid] = useState(props.routeUid)
  const [moduleUid, setModuleUid] = useState(props.moduleUid)
  const [description, setDescription] = useState(props.description)
  const [mpath] = useState('')
  const [allModules, setAllModules] = useState([])

  const [error, setError] = useState(true)
  const { t } = useTranslation()

  const { loading: loadingModulesQuery } = useQuery(GetAllModulesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAllModules }) => {
      setAllModules(getAllModules)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const [saveModule, { loadingEdit }] = useMutation(SaveRouteMutation)

  useEffect(() => {
    const module = allModules.find((module) => module.moduleUid === props.moduleUid)

    if (module) {
      setModuleUid({ label: module.name, id: module.moduleUid, value: module.moduleUid })
    }
    
    setRouteUid(props.routeUid)
    setDescription(props.description)
  }, [props])

  const clearForm = () => {
    setRouteUid('')
    setModuleUid('')
    setDescription('')
  }

  const onCancel = () => {
    clearForm()

    props.onAfterCancel()
  }

  const onClickSaveButton = () => {
    const route = props.allRoutes.find((current) => current.description === description)
    if (route && routeUid === '') {
      toastr.error(t('pages.route.errors.duplicated'))

      return
    }

    saveModule({
      variables: {
        route: {
          routeUid,
          moduleUid: moduleUid.value,
          mpath,
          parentRouteUid: null,
          description,
        }
      }
    })
      .then(({ data: { mutateRoute } }) => {
        clearForm()

        const module = allModules.find((module) => module.moduleUid === mutateRoute.moduleUid)

        props.onAfterSave({ ...mutateRoute, moduleName: module.name })

        toastr.success(t('toastr.success'))
      })
      .catch(() => {
        toastr.error(t('pages.module.errors.insert'))
      })
  }

  const disableSaveButton = () => {
    return !(moduleUid !== '' && description !== '')
  }

  return (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item xs={6}>
        <Typography>
          {t('labels.module')}
        </Typography>
        <ComboBox
          placeholder={t('placeholders.module')}
          value={moduleUid}
          onChange={(value) => setModuleUid(value)}
          data={allModules}
          disabled={false}
          loading={loadingModulesQuery}
          properties={{ label: 'name', value: 'moduleUid', id: 'moduleUid' }} />
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {t('labels.description')}
        </Typography>
        <DynamicInput
          input={'description'}
          onInput={(value) => setDescription(value)}
          id={{
            placeholder: t('placeholders.description'),
          }}
          error={error}
          errorValidate={(newError) => setError(newError)}
          value={description} />
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ margin: '20px 0 10px 0' }} />
      </Grid>
      <Grid item xs={2}>
        <ButtonDefault
          disabled={disableSaveButton()}
          text={t('labels.save')}
          onClick={onClickSaveButton}
          loading={loadingEdit}
          IconProps={Add} />
      </Grid>
      <Grid item xs={2}>
        <ButtonDefault
          text={t('labels.cancel')}
          onClick={onCancel}
          loading={loadingEdit}
          IconProps={Cancel}
        />
      </Grid>
    </Grid>
  )
}

export default RouteEdit