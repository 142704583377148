import React from 'react'
import Select from 'react-select'

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  background: {
    width: '100%',
    borderBlockColor: 'white',
  },
}))

function ComboBox(props) {
  const { error, errorMsg } = props
  const classes = useStyles()
  
  const options = props.data.map((item) => {
    return { label: item[props.properties.label], value: item[props.properties.value], id: item[props.properties.id] }  
  })


  function handleChange(data) {
    props.onChange(data)
  }

  return (
    <FormControl style={{ width: '100%' }} error={error}>
      {props.loading ? <CircularProgress style={{ width: '30px', height: '30px' }} /> :
        <Select
          className={classes.background}
          allowSelectAll={props.allowSelectAll}
          value={props.value}
          onChange={handleChange}
          displayEmpty
          closeMenuOnSelect={props.closeMenuOnSelect}
          isMulti={props.isMulti}
          isClearable={true}
          options={options}
          isDisabled={props.disabled}
          placeholder={props.placeholder}
        >
        </Select>
      }
      {error && <FormHelperText>{errorMsg}</FormHelperText>}
    </FormControl>
  )
}

export default ComboBox
