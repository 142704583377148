import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import PageBase from '../PageBase'
import MainContainer from '../../containers/Admin'
import RouteForm from '../../containers/RouteForm'
import DataGrid from '../../components/DataGrid'

import GetAllRoutesQuery from '../../../graphql/get-all-routes'
import DeleteModuleMutation from '../../../graphql/delete-route'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '0 0 0 25px',
    margin: '-40px'
  },
  background: {
    backgroundColor: '#f6f6f6',
    backgroundSize: 'cover',
  },
  button: {
    color: '#f6f6f6',
  },
  label: {
    'font-size': '22px',
    'font-family': '\'Natura Sans\', Roboto, Helvetica, Arial, sans-serif\'',
    'font-weight': '500',
  },
  padding: '20px 0 41px 0',
  Grid: {
    padding: '20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  paper: {
    border: '10px'
  },
  control: {
    padding: theme.spacing(2),
  },
}))

const initialState = {
  routeUid: '',
  description: '',
  moduleUid: '',
}

function RouteList(props) {
  const [allRoutes, setAllRoutes] = useState([])
  const [expanded, setExpanded] = React.useState('list')
  const [editingRoute, setEditingRoute] = useState(initialState)

  const handleActivePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const classes = useStyles()
  const { t } = useTranslation()

  const { loading: loadingQuery } = useQuery(GetAllRoutesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAllRoutes }) => {
      const formattedRoutes = getAllRoutes
        .map((route) => ({
          ...route, moduleName: route.module.name, moduleUid: route.module.moduleUid
        }))

      setAllRoutes(formattedRoutes)
    },
    onError: () => toastr.error(t('toastr.error'))
  })

  const [deleteMutation, { loading: loadingDelete }]
    = useMutation(DeleteModuleMutation)

  const columns = [
    { id: 'description', numeric: false, disablePadding: false, label: t('labels.name'), isNumber: false, toEdit: true },
    { id: 'moduleName', numeric: false, disablePadding: false, label: t('labels.module'), isNumber: false, toEdit: true },
    { id: 'updatedAt', numeric: false, disablePadding: false, label: t('labels.updatedAt'), isNumber: false, toEdit: true },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: t('labels.updatedBy'), isNumber: false, toEdit: true },
  ]

  const onAfterSave = (row) => {
    setAllRoutes([
      ...allRoutes.filter((route) => route.routeUid !== row.routeUid), row]
    )

    setEditingRoute(initialState)

    setExpanded('list')
  }

  const onAfterCancel = () => {
    setEditingRoute(initialState)

    setExpanded('list')
  }

  const onRemove = (row) => {
    deleteMutation({ variables: { routeUid: row.routeUid } })
      .then(() => {
        setAllRoutes(allRoutes.filter((item) => item.routeUid !== row.routeUid))

        setEditingRoute(initialState)

        toastr.success(t('toastr.success'))
      })
      .catch(() => {
        toastr.error(t('pages.route.errors.delete'))
      })
  }

  const onEdit = (row) => {
    const selected = allRoutes.find((module) => module.routeUid === row.routeUid)

    setExpanded('edit')
    setEditingRoute(selected)
  }

  const renderTable = () => {
    return <DataGrid
      onRemove={onRemove}
      columns={columns}
      rows={allRoutes}
      loading={loadingQuery || loadingDelete}
      onEdit={onEdit}
    />
  }

  return (
    <MainContainer {...props}>
      <PageBase {...props} title={t('titles.routesPage')}>
        <Accordion expanded={expanded === 'edit'} onChange={handleActivePanel('edit')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('labels.createNew')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RouteForm allRoutes={allRoutes} {...editingRoute} onAfterCancel={onAfterCancel} onAfterSave={onAfterSave} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'list'} onChange={handleActivePanel('list')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('labels.records')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loadingQuery || loadingDelete ?
              <div className={classes.progress}>
                <CircularProgress />
              </div> : renderTable()}
          </AccordionDetails>
        </Accordion>
      </PageBase>
    </MainContainer>
  )
}

export default RouteList