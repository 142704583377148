import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Hidden } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useStyles from './styles'
import LoginForm from '../../containers/LoginForm'

const backgroundImage = require('../../../images/login-background.jpg')
// const backgroundImage = require('../../../images/login-background-2.jpg')
const logoImage = require('../../../images/logos/natura.svg')
// const logoImage = require('../../../images/logos/natura-e-co.svg')

export default function Login(props) {
  const classes = useStyles(props.theme)
  const { t } = useTranslation()

  return (
    <Grid container style={{ height: '100%' }}>
      <Hidden xsDown>
        <Grid item md={6} sm={5} className={classNames(classes.imageContainer)}>
          <img src={backgroundImage} alt={t('pages.login.imageAlts.background')} height={'100%'} />
          <div className={classNames(classes.imageOverlay)}>
            <img src={logoImage} alt={t('pages.login.imageAlts.logo')} width={200} />
          </div>
        </Grid>
        <Grid item md={6} sm={7}>
          <LoginForm t={t} setAuthorization={props.setAuthorization} />
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid xs={12} className={classNames(classes.mobileMain)}>
          <img 
            src={backgroundImage} 
            alt={t('pages.login.imageAlts.background')} 
            height={'100%'} />
          <Grid container className={classNames(classes.mobileContent)}>
            <Grid xs={12} className={classNames(classes.mobileHeader)}>
              <img src={logoImage} alt={t('pages.login.imageAlts.logo')} width={100} />
            </Grid>
            <Grid xs={12} className={classNames(classes.mobileCardHolder)}>
              <Card className={classNames(classes.mobileCardHolder)}>
                <LoginForm t={t} setAuthorization={props.setAuthorization} />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  )
}

Login.propTypes = {
  t: PropTypes.func // eslint-disable-line
}