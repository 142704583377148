import React from 'react'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import style from './style'

const ButtonBox = styled.div`
  ${style((props) => props.disabled)}
  .button{
    background-color: ${ (props) => props.disabled ? '#e0e0e0' : '#4285f4'};
    color:  ${(props) => props.disabled ? '#4285f4' : '#fff'};  
    position:relative;
    text-decoration: none !important;
  }

  .typeIcon{
    color:  ${(props) => props.disabled ? '#e0e0e0 !important' : '#4285f4 !important'};  
  }

  .button:hover{
    background-color: ${(props) => props.disabled ? '#4285f4' : '#e0e0e0'};
    color: ${(props) => props.disabled ? '#fff' : '#4285f4'};
  }
  
  a{
    border: 0px solid #fff !important;
    text-decoration: none !important;
  }
`


const ButtonDefault = ({
  big,
  disabled,
  download,
  downloadLink,
  fillStyle,
  hidden,
  IconProps,
  text,
  loading,
  mini,
  reverse,
  typeIcon,
  ...props
}) => {

  const onClickHandler = () => {
    props.onClick()
  }


  if (hidden) {
    return (
      <div> </div>
    )
  }
  else {
    if (!download) {
      return (
        <ButtonBox disabled={disabled ? disabled : false}>
          <Button
            disabled={disabled || loading}
            onClick={onClickHandler}
            style={{ backgroundColor: `${loading ? '#e0e0e0' : ''}` }}
            className={`button ${reverse ? 'button-reverse' : ' '}
               ${ typeIcon ? 'typeIcon' : ' '}  ${big ? 'big' : ' '} 
               ${ mini ? 'mini' : ' '}
               ${ fillStyle ? 'button-fill' : ''}`}>

            {loading ? <CircularProgress style={{ width: '25px', height: '25px' }} /> : <IconProps />}

            {typeIcon ? '' : <div className='text'> {text} </div>}
          </Button>
        </ButtonBox>
      )
    }
    else {
      return (
        <a href={downloadLink !== null ? downloadLink : ''} style={{ color: 'transparent' }} download>
          <ButtonBox disabled={disabled ? disabled : false}>
            <Button
              disabled={disabled || loading}
              onClick={onClickHandler}
              style={{ backgroundColor: `${loading ? '#e0e0e0' : ''}` }}
              className={`button ${reverse ? 'button-reverse' : ' '}
               ${ typeIcon ? 'typeIcon' : ' '}  ${big ? 'big' : ' '} 
               ${ fillStyle ? 'button-fill' : ''}`}>

              {loading ? <CircularProgress style={{ width: '25px', height: '25px' }} /> : <IconProps />}

              {typeIcon ? '' : <div className='text'> {text}</div>}
            </Button>
          </ButtonBox>
        </a>
      )
    }
  }
}

export default ButtonDefault