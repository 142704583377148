import { gql } from '@apollo/client'

export default gql`
  query GetCompanyAccess($roles: [String]!) {
    getCompanyAccess(roles: $roles) {
      companyId
      description
      urlImage
    }
  }
`
