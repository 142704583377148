import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const CardTitle = styled.div`
&{
  height: '100%';
}

.card{
  margin-bottom: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  boxShadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: initial;
}
.divider{
  margin: 0px 0 30px 0;
}
.title{
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
  font-family: 'Natura Sans', Roboto, Helvetica, Arial, sans-serif;
}
.errorMsg{
  font-weight: 600 
}
.box{     
    margin-bottom: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    height: 100%;
}

`


const CardWithTitle = ({ hasError, errorMsg, children, titleHidden, title }) => {

  return (
    <CardTitle>
      <Card className={'box card'} style={{ border: hasError ? '1px solid red' : '' }}>
        <CardContent>
          {titleHidden ? <React.Fragment></React.Fragment> :
            <div>
              <Typography className='title'>
                {title}
              </Typography>
              <Divider className='divider' />
            </div>
          }
          {children}
        </CardContent>
        {
          hasError &&
          <CardActions>
            <Typography className='errorMsg'>{errorMsg}</Typography>
          </CardActions>
        }
      </Card>
    </CardTitle>
  )
}

export default CardWithTitle
